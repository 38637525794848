@charset "UTF-8";
/*!
* Bootstrap v5.1.3 (https://getbootstrap.com/)
* Copyright 2011-2021 The Bootstrap Authors
* Copyright 2011-2021 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/
:root {
  --bs-blue:#0d6efd;
  --bs-indigo:#6610f2;
  --bs-purple:#6f42c1;
  --bs-pink:#d63384;
  --bs-red:#dc3545;
  --bs-orange:#fd7e14;
  --bs-yellow:#ffc107;
  --bs-green:#198754;
  --bs-teal:#20c997;
  --bs-cyan:#0dcaf0;
  --bs-white:#fff;
  --bs-gray:#6c757d;
  --bs-gray-dark:#343a40;
  --bs-gray-100:#f8f9fa;
  --bs-gray-200:#e9ecef;
  --bs-gray-300:#dee2e6;
  --bs-gray-400:#ced4da;
  --bs-gray-500:#adb5bd;
  --bs-gray-600:#6c757d;
  --bs-gray-700:#495057;
  --bs-gray-800:#343a40;
  --bs-gray-900:#212529;
  --bs-primary:#0d6efd;
  --bs-secondary:#6c757d;
  --bs-success:#198754;
  --bs-info:#0dcaf0;
  --bs-warning:#ffc107;
  --bs-danger:#dc3545;
  --bs-light:#f8f9fa;
  --bs-dark:#212529;
  --bs-primary-rgb:13,110,253;
  --bs-secondary-rgb:108,117,125;
  --bs-success-rgb:25,135,84;
  --bs-info-rgb:13,202,240;
  --bs-warning-rgb:255,193,7;
  --bs-danger-rgb:220,53,69;
  --bs-light-rgb:248,249,250;
  --bs-dark-rgb:33,37,41;
  --bs-white-rgb:255,255,255;
  --bs-black-rgb:0,0,0;
  --bs-body-color-rgb:33,37,41;
  --bs-body-bg-rgb:255,255,255;
  --bs-font-sans-serif:system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient:linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family:var(--bs-font-sans-serif);
  --bs-body-font-size:1rem;
  --bs-body-font-weight:400;
  --bs-body-line-height:1.5;
  --bs-body-color:#212529;
  --bs-body-bg:#fff;
}

*, ::after, ::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 1200px) {
  .h1, h1 {
    font-size: 2.5rem;
  }
}
.h2, h2 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  .h2, h2 {
    font-size: 2rem;
  }
}
.h3, h3 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  .h3, h3 {
    font-size: 1.75rem;
  }
}
.h4, h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  .h4, h4 {
    font-size: 1.5rem;
  }
}
.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-bs-original-title], abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol, ul {
  padding-left: 2rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

.small, small {
  font-size: 0.875em;
}

.mark, mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub, sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}
.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}
.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}
.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}
.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}
.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x:1.5rem;
  --bs-gutter-y:0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0, .gx-0 {
  --bs-gutter-x:0;
}

.g-0, .gy-0 {
  --bs-gutter-y:0;
}

.g-1, .gx-1 {
  --bs-gutter-x:0.25rem;
}

.g-1, .gy-1 {
  --bs-gutter-y:0.25rem;
}

.g-2, .gx-2 {
  --bs-gutter-x:0.5rem;
}

.g-2, .gy-2 {
  --bs-gutter-y:0.5rem;
}

.g-3, .gx-3 {
  --bs-gutter-x:1rem;
}

.g-3, .gy-3 {
  --bs-gutter-y:1rem;
}

.g-4, .gx-4 {
  --bs-gutter-x:1.5rem;
}

.g-4, .gy-4 {
  --bs-gutter-y:1.5rem;
}

.g-5, .gx-5 {
  --bs-gutter-x:3rem;
}

.g-5, .gy-5 {
  --bs-gutter-y:3rem;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x:0;
  }
  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y:0;
  }
  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x:0.25rem;
  }
  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y:0.25rem;
  }
  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x:0.5rem;
  }
  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y:0.5rem;
  }
  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x:1rem;
  }
  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y:1rem;
  }
  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x:1.5rem;
  }
  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y:1.5rem;
  }
  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x:3rem;
  }
  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y:3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0, .gx-md-0 {
    --bs-gutter-x:0;
  }
  .g-md-0, .gy-md-0 {
    --bs-gutter-y:0;
  }
  .g-md-1, .gx-md-1 {
    --bs-gutter-x:0.25rem;
  }
  .g-md-1, .gy-md-1 {
    --bs-gutter-y:0.25rem;
  }
  .g-md-2, .gx-md-2 {
    --bs-gutter-x:0.5rem;
  }
  .g-md-2, .gy-md-2 {
    --bs-gutter-y:0.5rem;
  }
  .g-md-3, .gx-md-3 {
    --bs-gutter-x:1rem;
  }
  .g-md-3, .gy-md-3 {
    --bs-gutter-y:1rem;
  }
  .g-md-4, .gx-md-4 {
    --bs-gutter-x:1.5rem;
  }
  .g-md-4, .gy-md-4 {
    --bs-gutter-y:1.5rem;
  }
  .g-md-5, .gx-md-5 {
    --bs-gutter-x:3rem;
  }
  .g-md-5, .gy-md-5 {
    --bs-gutter-y:3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x:0;
  }
  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y:0;
  }
  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x:0.25rem;
  }
  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y:0.25rem;
  }
  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x:0.5rem;
  }
  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y:0.5rem;
  }
  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x:1rem;
  }
  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y:1rem;
  }
  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x:1.5rem;
  }
  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y:1.5rem;
  }
  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x:3rem;
  }
  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y:3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x:0;
  }
  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y:0;
  }
  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x:0.25rem;
  }
  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y:0.25rem;
  }
  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x:0.5rem;
  }
  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y:0.5rem;
  }
  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x:1rem;
  }
  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y:1rem;
  }
  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x:1.5rem;
  }
  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y:1.5rem;
  }
  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x:3rem;
  }
  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y:3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x:0;
  }
  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y:0;
  }
  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x:0.25rem;
  }
  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y:0.25rem;
  }
  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x:0.5rem;
  }
  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y:0.5rem;
  }
  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x:1rem;
  }
  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y:1rem;
  }
  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x:1.5rem;
  }
  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y:1.5rem;
  }
  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x:3rem;
  }
  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y:3rem;
  }
}
.table {
  --bs-table-bg:transparent;
  --bs-table-accent-bg:transparent;
  --bs-table-striped-color:#212529;
  --bs-table-striped-bg:rgba(0, 0, 0, 0.05);
  --bs-table-active-color:#212529;
  --bs-table-active-bg:rgba(0, 0, 0, 0.1);
  --bs-table-hover-color:#212529;
  --bs-table-hover-bg:rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
          box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg:var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg:var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg:var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg:#cfe2ff;
  --bs-table-striped-bg:#c5d7f2;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#bacbe6;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#bfd1ec;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg:#e2e3e5;
  --bs-table-striped-bg:#d7d8da;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#cbccce;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#d1d2d4;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg:#d1e7dd;
  --bs-table-striped-bg:#c7dbd2;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#bcd0c7;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#c1d6cc;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg:#cff4fc;
  --bs-table-striped-bg:#c5e8ef;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#badce3;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#bfe2e9;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg:#fff3cd;
  --bs-table-striped-bg:#f2e7c3;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#e6dbb9;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#ece1be;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg:#f8d7da;
  --bs-table-striped-bg:#eccccf;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#dfc2c4;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e5c7ca;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg:#f8f9fa;
  --bs-table-striped-bg:#ecedee;
  --bs-table-striped-color:#000;
  --bs-table-active-bg:#dfe0e1;
  --bs-table-active-color:#000;
  --bs-table-hover-bg:#e5e6e7;
  --bs-table-hover-color:#000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg:#212529;
  --bs-table-striped-bg:#2c3034;
  --bs-table-striped-color:#fff;
  --bs-table-active-bg:#373b3e;
  --bs-table-active-color:#fff;
  --bs-table-hover-bg:#323539;
  --bs-table-hover-color:#fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}

.form-check-input[type=radio] {
  border-radius: 50%;
}

.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5;
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check:disabled + .btn, .btn-check[disabled] + .btn {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}

.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}

.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}

.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
}

.input-group > .form-control, .input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus, .input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block;
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #198754;
}

.form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"], .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #198754;
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #198754;
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 1;
}

.input-group .form-control.is-valid:focus, .input-group .form-select.is-valid:focus, .was-validated .input-group .form-control:valid:focus, .was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545;
}

.form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"], .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #dc3545;
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
  z-index: 2;
}

.input-group .form-control.is-invalid:focus, .input-group .form-select.is-invalid:focus, .was-validated .input-group .form-control:invalid:focus, .was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}

.btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:active + .btn-info, .btn-check:checked + .btn-info, .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-check:active + .btn-warning, .btn-check:checked + .btn-warning, .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}

.btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-check:active + .btn-danger, .btn-check:checked + .btn-danger, .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}

.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-check:active + .btn-dark, .btn-check:checked + .btn-dark, .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}

.btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  -webkit-box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
          box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0a58ca;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
}

.btn-group-lg > .btn, .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-group-sm > .btn, .btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    -webkit-transition: none;
    transition: none;
  }
}
.dropdown, .dropend, .dropstart, .dropup {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position:start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position:end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position:start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position:end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position:start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position:end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position:start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position:end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position:start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position:end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position:start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position:end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn ~ .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    -webkit-transition: none;
    transition: none;
  }
}
.nav-link:focus, .nav-link:hover {
  color: #0a58ca;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl, .navbar > .container-xxl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem;
          box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-sm .offcanvas-bottom, .navbar-expand-sm .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-md .offcanvas-bottom, .navbar-expand-md .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-lg .offcanvas-bottom, .navbar-expand-lg .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xl .offcanvas-bottom, .navbar-expand-xl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xxl .offcanvas-bottom, .navbar-expand-xxl .offcanvas-top {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
          transform: none;
}

.navbar-expand .offcanvas-bottom, .navbar-expand .offcanvas-top {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}

.navbar-expand .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0;
  }
}
.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}

.accordion-button::after {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "https://asset.uibucket.net/");
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}

.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}

.alert-dark .alert-link {
  color: #101214;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}
.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #084298;
  background-color: #bacbe6;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #0f5132;
  background-color: #bcd0c7;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #664d03;
  background-color: #e6dbb9;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #842029;
  background-color: #dfc2c4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #141619;
  background-color: #bebebf;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
      user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}

.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
  padding: 0.4rem 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
  padding: 0 0.4rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::after, .popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .bs-popover-top > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow, .bs-popover-end > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .bs-popover-end > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow, .bs-popover-start > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .bs-popover-start > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
          filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}
.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
          transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
  animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
  animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}

.link-primary:focus, .link-primary:hover {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:focus, .link-secondary:hover {
  color: #565e64;
}

.link-success {
  color: #198754;
}

.link-success:focus, .link-success:hover {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}

.link-info:focus, .link-info:hover {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}

.link-warning:focus, .link-warning:hover {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}

.link-danger:focus, .link-danger:hover {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}

.link-light:focus, .link-light:hover {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}

.link-dark:focus, .link-dark:hover {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio:100%;
}

.ratio-4x3 {
  --bs-aspect-ratio:75%;
}

.ratio-16x9 {
  --bs-aspect-ratio:56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio:42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
}

.vstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: -ms-grid !important;
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      -ms-grid-row-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      -ms-grid-row-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      -ms-grid-row-align: stretch !important;
      align-self: stretch !important;
}

.order-first {
  -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
          order: -1 !important;
}

.order-0 {
  -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
          order: 0 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
          order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
          order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
          order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
          order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
          order: 5 !important;
}

.order-last {
  -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
          order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity:1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity:1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity:1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity:1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity:1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity:1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity:1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity:1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity:1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity:1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity:1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity:1;
  color: #6c757d !important;
}

.text-black-50 {
  --bs-text-opacity:1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity:1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity:1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity:0.25;
}

.text-opacity-50 {
  --bs-text-opacity:0.5;
}

.text-opacity-75 {
  --bs-text-opacity:0.75;
}

.text-opacity-100 {
  --bs-text-opacity:1;
}

.bg-primary {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity:1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity:1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity:0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity:0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity:0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity:0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity:1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
      user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
      user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }
  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }
  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }
  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }
  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }
  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }
  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }
  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }
  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }
  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        -ms-grid-row-align: auto !important;
        align-self: auto !important;
  }
  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }
  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }
  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        -ms-grid-row-align: center !important;
        align-self: center !important;
  }
  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        -ms-grid-row-align: stretch !important;
        align-self: stretch !important;
  }
  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }
  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: -ms-grid !important;
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  -webkit-transition-duration: 50ms;
          transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  -webkit-transition-delay: 50ms;
          transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  -webkit-transition-duration: 0.1s;
          transition-duration: 0.1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  -webkit-transition-duration: 0.15s;
          transition-duration: 0.15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.3s;
          transition-delay: 0.3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  -webkit-transition-duration: 0.35s;
          transition-duration: 0.35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.35s;
          transition-delay: 0.35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.4s;
          transition-delay: 0.4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  -webkit-transition-duration: 0.45s;
          transition-duration: 0.45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.45s;
          transition-delay: 0.45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  -webkit-transition-duration: 0.55s;
          transition-duration: 0.55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.55s;
          transition-delay: 0.55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  -webkit-transition-duration: 0.6s;
          transition-duration: 0.6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.6s;
          transition-delay: 0.6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  -webkit-transition-duration: 0.65s;
          transition-duration: 0.65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.65s;
          transition-delay: 0.65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  -webkit-transition-duration: 0.7s;
          transition-duration: 0.7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.7s;
          transition-delay: 0.7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  -webkit-transition-duration: 0.75s;
          transition-duration: 0.75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.75s;
          transition-delay: 0.75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  -webkit-transition-duration: 0.8s;
          transition-duration: 0.8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.8s;
          transition-delay: 0.8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  -webkit-transition-duration: 0.85s;
          transition-duration: 0.85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.85s;
          transition-delay: 0.85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  -webkit-transition-duration: 0.9s;
          transition-duration: 0.9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.9s;
          transition-delay: 0.9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  -webkit-transition-duration: 0.95s;
          transition-duration: 0.95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  -webkit-transition-delay: 0.95s;
          transition-delay: 0.95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  -webkit-transition-duration: 1.05s;
          transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.05s;
          transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  -webkit-transition-duration: 1.1s;
          transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.1s;
          transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  -webkit-transition-duration: 1.15s;
          transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.15s;
          transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  -webkit-transition-duration: 1.2s;
          transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.2s;
          transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  -webkit-transition-duration: 1.25s;
          transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.25s;
          transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  -webkit-transition-duration: 1.3s;
          transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.3s;
          transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  -webkit-transition-duration: 1.35s;
          transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.35s;
          transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  -webkit-transition-duration: 1.4s;
          transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.4s;
          transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  -webkit-transition-duration: 1.45s;
          transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.45s;
          transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  -webkit-transition-duration: 1.5s;
          transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.5s;
          transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  -webkit-transition-duration: 1.55s;
          transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.55s;
          transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  -webkit-transition-duration: 1.6s;
          transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.6s;
          transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  -webkit-transition-duration: 1.65s;
          transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.65s;
          transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  -webkit-transition-duration: 1.7s;
          transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.7s;
          transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  -webkit-transition-duration: 1.75s;
          transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.75s;
          transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  -webkit-transition-duration: 1.8s;
          transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.8s;
          transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  -webkit-transition-duration: 1.85s;
          transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.85s;
          transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  -webkit-transition-duration: 1.9s;
          transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.9s;
          transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  -webkit-transition-duration: 1.95s;
          transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  -webkit-transition-delay: 1.95s;
          transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  -webkit-transition-duration: 2s;
          transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  -webkit-transition-delay: 2s;
          transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  -webkit-transition-duration: 2.05s;
          transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.05s;
          transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  -webkit-transition-duration: 2.1s;
          transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.1s;
          transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  -webkit-transition-duration: 2.15s;
          transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.15s;
          transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  -webkit-transition-duration: 2.2s;
          transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.2s;
          transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  -webkit-transition-duration: 2.25s;
          transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.25s;
          transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  -webkit-transition-duration: 2.3s;
          transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.3s;
          transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  -webkit-transition-duration: 2.35s;
          transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.35s;
          transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  -webkit-transition-duration: 2.4s;
          transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.4s;
          transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  -webkit-transition-duration: 2.45s;
          transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.45s;
          transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  -webkit-transition-duration: 2.5s;
          transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.5s;
          transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  -webkit-transition-duration: 2.55s;
          transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.55s;
          transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  -webkit-transition-duration: 2.6s;
          transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.6s;
          transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  -webkit-transition-duration: 2.65s;
          transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.65s;
          transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  -webkit-transition-duration: 2.7s;
          transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.7s;
          transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  -webkit-transition-duration: 2.75s;
          transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.75s;
          transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  -webkit-transition-duration: 2.8s;
          transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.8s;
          transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  -webkit-transition-duration: 2.85s;
          transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.85s;
          transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  -webkit-transition-duration: 2.9s;
          transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.9s;
          transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  -webkit-transition-duration: 2.95s;
          transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  -webkit-transition-delay: 2.95s;
          transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  -webkit-transition-duration: 3s;
          transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  -webkit-transition-delay: 0;
          transition-delay: 0;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  -webkit-transition-delay: 3s;
          transition-delay: 3s;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
          transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
          transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
          transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
          transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
          transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
          transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
          transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  -webkit-transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

[data-aos=fade-up] {
  -webkit-transform: translate3d(0, 100px, 0);
          transform: translate3d(0, 100px, 0);
}

[data-aos=fade-down] {
  -webkit-transform: translate3d(0, -100px, 0);
          transform: translate3d(0, -100px, 0);
}

[data-aos=fade-right] {
  -webkit-transform: translate3d(-100px, 0, 0);
          transform: translate3d(-100px, 0, 0);
}

[data-aos=fade-left] {
  -webkit-transform: translate3d(100px, 0, 0);
          transform: translate3d(100px, 0, 0);
}

[data-aos=fade-up-right] {
  -webkit-transform: translate3d(-100px, 100px, 0);
          transform: translate3d(-100px, 100px, 0);
}

[data-aos=fade-up-left] {
  -webkit-transform: translate3d(100px, 100px, 0);
          transform: translate3d(100px, 100px, 0);
}

[data-aos=fade-down-right] {
  -webkit-transform: translate3d(-100px, -100px, 0);
          transform: translate3d(-100px, -100px, 0);
}

[data-aos=fade-down-left] {
  -webkit-transform: translate3d(100px, -100px, 0);
          transform: translate3d(100px, -100px, 0);
}

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
}

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
}

[data-aos=zoom-in] {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
}

[data-aos=zoom-in-up] {
  -webkit-transform: translate3d(0, 100px, 0) scale(0.6);
          transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos=zoom-in-down] {
  -webkit-transform: translate3d(0, -100px, 0) scale(0.6);
          transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos=zoom-in-right] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(0.6);
          transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos=zoom-in-left] {
  -webkit-transform: translate3d(100px, 0, 0) scale(0.6);
          transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos=zoom-out] {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

[data-aos=zoom-out-up] {
  -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
          transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos=zoom-out-down] {
  -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
          transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos=zoom-out-right] {
  -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
          transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos=zoom-out-left] {
  -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
          transform: translate3d(100px, 0, 0) scale(1.2);
}

[data-aos^=slide][data-aos^=slide] {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos^=slide][data-aos^=slide].aos-animate {
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}

[data-aos=slide-up] {
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
}

[data-aos=slide-down] {
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
}

[data-aos=slide-right] {
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

[data-aos=slide-left] {
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

[data-aos^=flip][data-aos^=flip] {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

[data-aos=flip-left] {
  -webkit-transform: perspective(2500px) rotateY(-100deg);
          transform: perspective(2500px) rotateY(-100deg);
}

[data-aos=flip-left].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-right] {
  -webkit-transform: perspective(2500px) rotateY(100deg);
          transform: perspective(2500px) rotateY(100deg);
}

[data-aos=flip-right].aos-animate {
  -webkit-transform: perspective(2500px) rotateY(0);
          transform: perspective(2500px) rotateY(0);
}

[data-aos=flip-up] {
  -webkit-transform: perspective(2500px) rotateX(-100deg);
          transform: perspective(2500px) rotateX(-100deg);
}

[data-aos=flip-up].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

[data-aos=flip-down] {
  -webkit-transform: perspective(2500px) rotateX(100deg);
          transform: perspective(2500px) rotateX(100deg);
}

[data-aos=flip-down].aos-animate {
  -webkit-transform: perspective(2500px) rotateX(0);
          transform: perspective(2500px) rotateX(0);
}

.vbox-overlay {
  --vbox-tools-color:#fff;
  --vbox-title-background:#101010;
  --vbox-title-width:"auto";
  --vbox-title-radius:0;
  --vbox-share-background:#101010;
  --vbox-share-width:"auto";
  --vbox-share-radius:0;
  --vbox-padding:0;
}

.vbox-overlay *, .vbox-overlay :after, .vbox-overlay :before {
  -webkit-backface-visibility: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.vbox-overlay * {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}

.vbox-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
}

.vbox-share, .vbox-title {
  line-height: 1;
  position: fixed;
  z-index: 98;
  text-align: center;
  margin: 0 auto;
  color: var(--vbox-tools-color);
}

.vbox-title {
  font-size: 12px;
  background-color: var(--vbox-title-background);
  width: var(--vbox-title-width);
  border-radius: var(--vbox-title-radius);
  padding: 12px 54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.vbox-share {
  font-size: 24px;
  padding: 0 0.35em;
  background-color: var(--vbox-share-background);
  width: var(--vbox-share-width);
  border-radius: var(--vbox-share-radius);
}

.vbox-link-btn, button.vbox-link-btn, button.vbox-link-btn:active, button.vbox-link-btn:focus, button.vbox-link-btn:hover {
  border: none !important;
  background: 0 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: inherit !important;
  padding: 6px 12px;
  outline: 0;
  display: inline-block;
  cursor: pointer;
}

.vbox-share a {
  color: inherit !important;
  padding: 6px 12px;
  display: inline-block;
}

.vbox-share svg {
  z-index: 10;
  vertical-align: middle;
}

.vbox-close {
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  padding: 6px 15px;
  color: #000;
  color: var(--vbox-tools-color);
  border: 0;
  font-size: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.vbox-close:hover {
  opacity: 1;
}

.vbox-left-corner {
  cursor: pointer;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  line-height: 1;
  font-size: 12px;
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--vbox-tools-color);
}

.vbox-num {
  display: inline-block;
  padding: 12px 15px;
}

.vbox-left {
  left: 0;
}

.vbox-right {
  right: 0;
}

.vbox-top {
  top: 0;
}

.vbox-bottom {
  bottom: 0;
}

.vbox-next, .vbox-prev {
  position: fixed;
  top: 50%;
  margin-top: -15px;
  overflow: hidden;
  cursor: pointer;
  display: block;
  width: 45px;
  height: 45px;
  z-index: 99;
  opacity: 0.8;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.vbox-next:hover, .vbox-prev:hover {
  opacity: 1;
}

.vbox-next span, .vbox-prev span {
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: var(--vbox-tools-color);
  border-right-color: var(--vbox-tools-color);
  text-indent: -100px;
  position: absolute;
  top: 8px;
  display: block;
}

.vbox-prev {
  left: 15px;
}

.vbox-next {
  right: 15px;
}

.vbox-prev span {
  left: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.vbox-next span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: 10px;
}

.vbox-inline, .venoratio {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.venoratio::before {
  display: block;
  padding-top: var(--vb-aspect-ratio);
  content: "";
}

.venoratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: var(--vbox-padding);
}

.venoratio-1x1 {
  --vb-aspect-ratio:100%;
}

.venoratio-4x3 {
  --vb-aspect-ratio:calc(3 / 4 * 100%);
}

.venoratio-16x9 {
  --vb-aspect-ratio:calc(9 / 16 * 100%);
}

.venoratio-21x9 {
  --vb-aspect-ratio:calc(9 / 21 * 100%);
}

.venoratio-full {
  --vb-aspect-ratio:calc(100vh - 100px);
}

.vbox-child.vbox-inline, .vbox-child.venoratio {
  max-width: 100%;
}

.vbox-open {
  overflow: hidden;
}

.vbox-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  z-index: 20;
  max-height: 100%;
  padding: 30px 0;
}

.vbox-content {
  opacity: 0;
}

.vbox-content {
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px 4%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100%;
}

.vbox-container img {
  max-width: 100%;
  height: auto;
}

.vbox-child {
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  max-width: 100%;
  text-align: initial;
  padding: var(--vbox-padding);
}

.vbox-child img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none;
  display: block;
}

.vbox-grab .vbox-child img {
  cursor: -webkit-grab;
  cursor: grab;
}

.vbox-child > iframe {
  border: none !important;
}

.vbox-content.swipe-left {
  margin-left: -200px !important;
}

.vbox-content.swipe-right {
  margin-left: 200px !important;
}

.vbox-preloader {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.vbox-preloader .vbox-preloader-inner {
  opacity: 1;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.vbox-hidden {
  display: none;
}

.vbox-preloader.vbox-hidden .vbox-preloader-inner {
  opacity: 0;
}

.vbox-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translateZ(-1px);
  transform: translateZ(-1px);
  z-index: 0;
}

.vbox-tooltip {
  position: relative;
  display: inline-block;
}

.vbox-tooltip .vbox-tooltip-text {
  visibility: hidden;
  color: #fff;
  text-align: center;
  padding: 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  margin-bottom: 2px;
  font-family: sans-serif;
}

.vbox-top .vbox-tooltip .vbox-tooltip-text {
  bottom: auto;
  top: 100%;
  margin-bottom: 0;
  margin-top: 2px;
}

.vbox-tooltip-inner {
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 6px;
  font-size: 10px;
}

.vbox-tooltip:hover .vbox-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.vbox-overlay {
  --sk-size:40px;
  --sk-color:#333;
}

.sk-center {
  margin: auto;
}

.sk-plane {
  width: var(--sk-size);
  height: var(--sk-size);
  background-color: var(--sk-color);
  -webkit-animation: sk-plane 1.2s infinite ease-in-out;
          animation: sk-plane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-plane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
            transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes sk-plane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0) rotateY(0);
            transform: perspective(120px) rotateX(0) rotateY(0);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.sk-chase {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot {
  100%, 80% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes sk-chase-dot {
  100%, 80% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  0%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  0%, 100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-bounce {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-bounce-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--sk-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation: sk-bounce 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sk-bounce-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  45%, 55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  45%, 55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-wave {
  width: var(--sk-size);
  height: var(--sk-size);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sk-wave-rect {
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  -webkit-animation: sk-wave 1.2s infinite ease-in-out;
          animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}

.sk-wave-rect:nth-child(2) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.sk-wave-rect:nth-child(3) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.sk-wave-rect:nth-child(4) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.sk-wave-rect:nth-child(5) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@-webkit-keyframes sk-wave {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes sk-wave {
  0%, 100%, 40% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
.sk-pulse {
  width: var(--sk-size);
  height: var(--sk-size);
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@-webkit-keyframes sk-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
.sk-flow {
  width: calc(var(--sk-size) * 1.3);
  height: calc(var(--sk-size) * 1.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.sk-flow-dot {
  width: 25%;
  height: 25%;
  background-color: var(--sk-color);
  border-radius: 50%;
  -webkit-animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
          animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
}

.sk-flow-dot:nth-child(1) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.sk-flow-dot:nth-child(2) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes sk-flow {
  0%, 100%, 80% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-flow {
  0%, 100%, 80% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-swing {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  -webkit-animation: sk-swing 1.8s infinite linear;
          animation: sk-swing 1.8s infinite linear;
}

.sk-swing-dot {
  width: 45%;
  height: 45%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-swing-dot 2s infinite ease-in-out;
          animation: sk-swing-dot 2s infinite ease-in-out;
}

.sk-swing-dot:nth-child(2) {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-swing {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-swing {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes sk-swing-dot {
  0%, 100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes sk-swing-dot {
  0%, 100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-circle {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-circle-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-dot:before {
  content: "";
  display: block;
  width: 15%;
  height: 15%;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-circle 1.2s infinite ease-in-out both;
          animation: sk-circle 1.2s infinite ease-in-out both;
}

.sk-circle-dot:nth-child(1) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.sk-circle-dot:nth-child(2) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}

.sk-circle-dot:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.sk-circle-dot:nth-child(4) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}

.sk-circle-dot:nth-child(5) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}

.sk-circle-dot:nth-child(6) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.sk-circle-dot:nth-child(7) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}

.sk-circle-dot:nth-child(8) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}

.sk-circle-dot:nth-child(9) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.sk-circle-dot:nth-child(10) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}

.sk-circle-dot:nth-child(11) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}

.sk-circle-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.sk-circle-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.sk-circle-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.sk-circle-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.sk-circle-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.sk-circle-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.sk-circle-dot:nth-child(7):before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.sk-circle-dot:nth-child(8):before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.sk-circle-dot:nth-child(9):before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.sk-circle-dot:nth-child(10):before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.sk-circle-dot:nth-child(11):before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circle {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circle {
  0%, 100%, 80% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-circle-fade {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: "";
  display: block;
  width: 15%;
  height: 15%;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-circle-fade 1.2s infinite ease-in-out both;
          animation: sk-circle-fade 1.2s infinite ease-in-out both;
}

.sk-circle-fade-dot:nth-child(1) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.sk-circle-fade-dot:nth-child(2) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}

.sk-circle-fade-dot:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.sk-circle-fade-dot:nth-child(4) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}

.sk-circle-fade-dot:nth-child(5) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}

.sk-circle-fade-dot:nth-child(6) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.sk-circle-fade-dot:nth-child(7) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}

.sk-circle-fade-dot:nth-child(8) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}

.sk-circle-fade-dot:nth-child(9) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.sk-circle-fade-dot:nth-child(10) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}

.sk-circle-fade-dot:nth-child(11) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}

.sk-circle-fade-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}

.sk-circle-fade-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

.sk-circle-fade-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}

.sk-circle-fade-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

.sk-circle-fade-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}

.sk-circle-fade-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

.sk-circle-fade-dot:nth-child(7):before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.sk-circle-fade-dot:nth-child(8):before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}

.sk-circle-fade-dot:nth-child(9):before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.sk-circle-fade-dot:nth-child(10):before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}

.sk-circle-fade-dot:nth-child(11):before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circle-fade {
  0%, 100%, 39% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  40% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circle-fade {
  0%, 100%, 39% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  40% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.sk-grid {
  width: var(--sk-size);
  height: var(--sk-size);
}

.sk-grid-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: var(--sk-color);
  float: left;
  -webkit-animation: sk-grid 1.3s infinite ease-in-out;
          animation: sk-grid 1.3s infinite ease-in-out;
}

.sk-grid-cube:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.sk-grid-cube:nth-child(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.sk-grid-cube:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.sk-grid-cube:nth-child(4) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.sk-grid-cube:nth-child(5) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.sk-grid-cube:nth-child(6) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.sk-grid-cube:nth-child(7) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.sk-grid-cube:nth-child(8) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}

.sk-grid-cube:nth-child(9) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

@-webkit-keyframes sk-grid {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-grid {
  0%, 100%, 70% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}
.sk-fold {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}

.sk-fold-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.sk-fold-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--sk-color);
  -webkit-animation: sk-fold 2.4s infinite linear both;
          animation: sk-fold 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}

.sk-fold-cube:nth-child(2) {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}

.sk-fold-cube:nth-child(4) {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}

.sk-fold-cube:nth-child(3) {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}

.sk-fold-cube:nth-child(2):before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.sk-fold-cube:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.sk-fold-cube:nth-child(3):before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes sk-fold {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0);
            transform: perspective(140px) rotateX(0);
    opacity: 1;
  }
  100%, 90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-fold {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0);
            transform: perspective(140px) rotateX(0);
    opacity: 1;
  }
  100%, 90% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.sk-wander {
  width: var(--sk-size);
  height: var(--sk-size);
  position: relative;
}

.sk-wander-cube {
  background-color: var(--sk-color);
  width: 20%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  --sk-wander-distance:calc(var(--sk-size) * 0.75);
  -webkit-animation: sk-wander 2s ease-in-out -2s infinite both;
          animation: sk-wander 2s ease-in-out -2s infinite both;
}

.sk-wander-cube:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}

.sk-wander-cube:nth-child(3) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-wander {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
            transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }
  50% {
    -webkit-transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-179deg);
            transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-180deg);
            transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(var(--sk-wander-distance)) rotate(-270deg) scale(0.6);
            transform: translateX(0) translateY(var(--sk-wander-distance)) rotate(-270deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes sk-wander {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  25% {
    -webkit-transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
            transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }
  50% {
    -webkit-transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-179deg);
            transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-180deg);
            transform: translateX(var(--sk-wander-distance)) translateY(var(--sk-wander-distance)) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(var(--sk-wander-distance)) rotate(-270deg) scale(0.6);
            transform: translateX(0) translateY(var(--sk-wander-distance)) rotate(-270deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0;
  padding: 0 0;
}

ul {
  margin: 0 0;
  padding: 0 0;
}

ul > li {
  list-style: none;
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1280px !important;
  }
}

.global__py {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (min-width: 1200px) {
  .global__py {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
.global__heading {
  margin: 0px 0 48px 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #101828;
}
@media screen and (min-width: 568px) {
  .global__heading {
    font-size: 42px;
  }
}
@media screen and (min-width: 768px) {
  .global__heading {
    font-size: 38px;
  }
}
@media screen and (min-width: 1200px) {
  .global__heading {
    font-size: 42px;
  }
}
.global__heading-w {
  color: #fff !important;
}
.global__title {
  margin: 13px 0 0 0;
  font-size: 32px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 992px) {
  .global__title {
    font-size: 48px !important;
  }
}
@media screen and (min-width: 1200px) {
  .global__title {
    font-size: 52px !important;
  }
}
@media screen and (min-width: 1400px) {
  .global__title {
    font-size: 60px !important;
  }
}
.global__title-dark {
  color: #101828;
}
.global__text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #101828;
  font-size: 16px;
  margin: 0 0 12px 0;
  text-transform: uppercase;
}
@media screen and (min-width: 568px) {
  .global__text {
    font-size: 20px;
  }
}
.global__text-w {
  color: #fff !important;
}
.global__rise {
  color: #344054;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
@media screen and (min-width: 768px) {
  .global__rise {
    font-size: 12px;
  }
}
@media screen and (min-width: 992px) {
  .global__rise {
    font-size: 14px;
  }
}
.global__desc {
  margin: 24px 0 40px 0;
  font-family: "Inter", sans-serif;
  color: #344054;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
}
@media screen and (min-width: 568px) {
  .global__desc {
    font-size: 18px;
  }
}
.global__btn {
  text-decoration: none;
  border: 1px solid transparent;
  color: #fff !important;
  background: #00715D !important;
  font-family: "Inter", sans-serif;
  font-size: 16px !important;
  font-weight: 500;
  border-radius: 8px;
  padding: 16px 30px !important;
  text-transform: capitalize;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 1px solid transparent;
}
.global__btn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #00715D !important;
  background: #fff !important;
  border: 1px solid #00715D;
}
.global__btn-arrow {
  position: relative;
  left: 6px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.global__arrow {
  margin: 25px 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 30px;
}
.global__arrow-default {
  background: #fff;
  -webkit-box-shadow: 0px 16px 32px rgba(0, 12, 48, 0.078);
          box-shadow: 0px 16px 32px rgba(0, 12, 48, 0.078);
  padding: 13px 14px;
  margin: 0 16px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 100px;
  color: #98A2B3;
  border: 1px solid transparent;
  z-index: 9;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.global__arrow-default:hover {
  color: #00715D;
  border: 1px solid #CCE3DF !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
@media screen and (min-width: 400px) {
  .global__arrow-default {
    padding: 16px 17px;
    font-size: 18px;
  }
}
.global__arrow-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-row: 2;
  grid-row: 2/2;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  width: 82px;
  position: relative;
  left: 50%;
}
@media screen and (min-width: 400px) {
  .global__arrow-left {
    left: 66%;
  }
}
@media screen and (min-width: 768px) {
  .global__arrow-left {
    left: 76%;
  }
}
@media screen and (min-width: 992px) {
  .global__arrow-left {
    left: 83%;
  }
}
@media screen and (min-width: 1200px) {
  .global__arrow-left {
    left: 88%;
  }
}
.global__arrow-right {
  width: 82px;
}
.global__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: auto;
  font-size: 22px;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.global__icon-white {
  background: rgba(0, 113, 93, 0.1) !important;
  color: #00715D;
}
.global__icon-wmedium {
  border: 1px solid #E6F1EF;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 113, 93, 0.1)), to(rgba(0, 113, 93, 0)));
  background: linear-gradient(180deg, rgba(0, 113, 93, 0.1) 0%, rgba(0, 113, 93, 0) 100%);
}
.global__icon-green {
  background: #00715D;
  color: #fff;
}
.global__white {
  background: #fff !important;
}
@media screen and (min-width: 992px) {
  .global__transparent {
    background: transparent !important;
  }
}

.slick-dots {
  margin: 30px 0 0 0;
  padding: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}

.slick-dots li button {
  display: none;
}

.slick-dots .slick-active {
  background: #00715D;
}

.slick-dots li {
  cursor: pointer;
  list-style: none;
  background: #98A2B3;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 8px;
}

#topBtn {
  position: fixed;
  bottom: 20px;
  right: 10px;
  font-size: 15px;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background: #00715D;
  color: #fff;
  border: none;
  cursor: pointer;
  display: none;
  z-index: 99999;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  -webkit-box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.4);
          box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.4);
}
#topBtn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #018e75;
}
@media screen and (min-width: 768px) {
  #topBtn {
    bottom: 40px;
    right: 40px;
    font-size: 18px;
    width: 50px;
    height: 50px;
  }
}

.menu {
  background: #fff;
  padding: 20px 0;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  z-index: 12;
}
@media screen and (min-width: 568px) {
  .menu {
    padding: 32px 0;
  }
}
@media screen and (min-width: 992px) {
  .menu__logo {
    width: 20%;
  }
}
@media screen and (min-width: 1200px) {
  .menu__logo {
    width: auto;
  }
}
.menu__logo-img {
  width: 70%;
}
@media screen and (min-width: 568px) {
  .menu__logo-img {
    width: 100%;
  }
}
.menu__toggle {
  outline: 0 !important;
  border: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: #00715D !important;
  font-size: 22px;
}
.menu__list {
  margin: 0 0;
}
.menu__list li {
  padding: 8px 0 !important;
  border-bottom: 1px solid #DAEBE8;
}
.menu__list li:hover {
  background: #E6F1EF;
}
@media screen and (min-width: 992px) {
  .menu__list li {
    padding: 0 16px !important;
    border-bottom: 1px solid transparent;
  }
  .menu__list li:hover {
    background: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .menu__list li {
    padding: 0 20px !important;
  }
}
@media screen and (min-width: 1366px) {
  .menu__list li {
    padding: 0 25px !important;
  }
}
.menu__list-dropdown {
  margin: 0 0;
}
.menu__list-dropdown--ul {
  position: relative;
  border: 1px solid rgba(62, 94, 80, 0.168627451) !important;
  -webkit-box-shadow: 0px 8px 34px 0px rgba(77, 77, 77, 0.07);
          box-shadow: 0px 8px 34px 0px rgba(77, 77, 77, 0.07);
  margin: 10px 0 0 0 !important;
  padding: 0 0 !important;
  z-index: 10;
  height: 250px;
  overflow-y: scroll;
}
.menu__list-dropdown--ul li:last-child {
  margin: 0 0 !important;
}
.menu__list-dropdown--ul li {
  margin: 0 0 8px 0;
  padding: 0 !important;
}
.menu__list-dropdown--ul a {
  font-family: "Inter", sans-serif;
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: #101828 !important;
  text-transform: capitalize;
  padding: 8px 1.2rem !important;
}
.menu__list-dropdown--ul a:hover {
  color: #2ea26d !important;
  background: rgba(158, 233, 199, 0.3411764706) !important;
}
.menu__list-dropdown--ul a:active {
  background-color: transparent !important;
}
.menu__list-link {
  padding: 0 0 !important;
  margin: 0 0 !important;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px !important;
  color: #101828 !important;
  text-transform: capitalize;
}
.menu__list-link:hover {
  color: #00715D !important;
}
@media screen and (min-width: 992px) {
  .menu__list-link {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1200px) {
  .menu__list-link {
    font-size: 16px !important;
  }
}
.menu__list-link--active {
  color: #00715D !important;
}
.menu__btn {
  margin: 0 0;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 11px 24px;
}
@media screen and (min-width: 992px) {
  .menu__btn {
    font-size: 14px !important;
    padding: 8px 20px;
  }
}
@media screen and (min-width: 1200px) {
  .menu__btn {
    font-size: 16px !important;
    padding: 11px 24px;
  }
}
.menu__btn-in {
  width: 100%;
  color: #00715D !important;
  border-radius: 8px;
  text-transform: capitalize;
  border: 1px solid #00715D !important;
  background: #fff !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.menu__btn-in:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #fff !important;
  background: #00715D !important;
}
@media screen and (min-width: 992px) {
  .menu__btn-in {
    width: auto;
    margin: 0 20px 0 0 !important;
  }
}
.menu__btn-up {
  width: 100%;
  color: #fff !important;
  background: #00715D !important;
  border-radius: 8px;
  text-transform: capitalize;
  border: 1px solid #00715D !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.menu__btn-up:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #00715D !important;
  background: #fff !important;
}
@media screen and (min-width: 992px) {
  .menu__btn-up {
    width: auto;
  }
}
.menu ::-webkit-scrollbar {
  width: 7px;
}
.menu ::-webkit-scrollbar-track {
  background: #CCE3DF;
}
.menu ::-webkit-scrollbar-thumb {
  background: #00715D;
  border-radius: 5px;
}

.hero {
  overflow-x: hidden;
  margin: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url(../img/banner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 580px;
}
@media screen and (min-width: 568px) {
  .hero {
    height: 730px;
  }
}
@media screen and (min-width: 992px) {
  .hero {
    height: 834px;
  }
}
.hero__hand {
  position: absolute;
  bottom: 0;
  right: -2%;
  background: white;
  padding: 12px 12px;
  border-radius: 20px;
  -webkit-transform: rotate(-29deg);
          transform: rotate(-29deg);
}
@media screen and (min-width: 992px) {
  .hero__hand {
    right: 3%;
  }
}
@media screen and (min-width: 1200px) {
  .hero__hand {
    right: 0%;
  }
}
@media screen and (min-width: 1366px) {
  .hero__hand {
    right: -2%;
  }
}
.hero__hand img {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.hero__banner {
  background-image: url(../img/banner-2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hero__two {
  padding: 20px 0 96px 0;
}
.hero__two-i {
  color: #00715D;
  background: #fff;
  margin: 0 8px 0 0;
  padding: 6px;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.hero__frame {
  margin: 0 0;
  position: relative;
}
.hero__frame-circle {
  width: 100%;
}
.hero__frame-person {
  position: absolute;
  left: -2%;
  top: -43%;
}
.hero__frame-lines {
  position: absolute;
  left: -11%;
  bottom: 5%;
}
.hero__frame-team {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  position: absolute;
  right: 7%;
  bottom: -5%;
  -webkit-box-shadow: 0px 8px 40px 0px rgba(60, 77, 74, 0.08);
          box-shadow: 0px 8px 40px 0px rgba(60, 77, 74, 0.08);
}
.hero__frame-team--text {
  text-transform: capitalize;
  font-size: 20px;
}

.hero__banner-two {
  position: relative;
}
.hero__banner-two::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  content: "";
  background: #F4FCF7;
  z-index: -1;
}
.hero__banner-two::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  content: "";
  z-index: -1;
  background-image: url(../img/blur-bg.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.hero__three {
  margin: 0 0;
  background-image: url(../img/header-img-3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hero__three-layer {
  background: linear-gradient(272deg, rgba(0, 113, 93, 0) 0%, #037762 100%);
  padding: 130px 0 76px 0;
}
.hero__three-gray {
  color: #EAECF0 !important;
}
.hero__three-i {
  color: #00715D;
  background: #EAECF0;
  margin: 0 8px 0 0;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.hero__three-btn {
  background: #fff !important;
  color: #101828 !important;
  border: 1px solid #fff;
}
.hero__three-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 30px 30px;
  border-radius: 20px;
  max-width: 337px;
}
.hero__three-box--num {
  color: #00715D;
  font-family: "Inter", sans-serif;
  font-size: 19px;
  font-weight: 600;
  margin: 0 0 12px 0;
  text-transform: capitalize;
}
@media screen and (min-width: 568px) {
  .hero__three-box--num {
    font-size: 24px;
  }
}
.hero__three-box--icon {
  margin: 0 12px 0 0;
  border-radius: 100px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 113, 93, 0.1)), to(rgba(0, 113, 93, 0)));
  background: linear-gradient(180deg, rgba(0, 113, 93, 0.1) 0%, rgba(0, 113, 93, 0) 100%);
  padding: 16px 16px;
}
.hero__three-progress {
  background: #E6F1EF;
  height: 8px;
}
.hero__three-progress--bar {
  background: #00715D;
}

.hero__banner-bg {
  background: -webkit-gradient(linear, left top, left bottom, from(#E6F1EF), to(rgba(230, 241, 239, 0)));
  background: linear-gradient(180deg, #E6F1EF 0%, rgba(230, 241, 239, 0) 100%);
}
.hero__four {
  padding: 78px 0 110px 0;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .hero__four {
    padding: 78px 0 100px 0;
  }
}
.hero__four-layer {
  position: relative;
}
.hero__four-layer::after {
  position: absolute;
  top: 27rem;
  right: 6%;
  width: 549px;
  height: 197px;
  content: "";
  z-index: 2;
  background-image: url(../img/header-back.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.hero__four-element img {
  position: absolute;
  right: 0%;
  bottom: -18%;
}
@media screen and (min-width: 1400px) {
  .hero__four-element img {
    right: -9%;
  }
}
.hero__four-image {
  position: relative;
  z-index: 4;
}
.hero__four-image img {
  border-radius: 55px;
  -webkit-transform: rotateY(30deg);
          transform: rotateY(30deg);
}
.hero__four-image::after {
  position: absolute;
  content: "";
  background-image: url(../img/blur-bg-2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 740px;
  height: 740px;
  left: 60%;
  z-index: -4;
  opacity: 0.6;
  border-radius: 50%/67% 60% 40% 40%;
}
.hero__four-frame {
  position: relative;
  z-index: 4;
}
.hero__four-frame--person {
  position: absolute;
  left: -2%;
  top: -43%;
}
.hero__four-frame--team {
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  position: absolute;
  left: -5%;
  bottom: -5%;
  -webkit-box-shadow: 0px 8px 40px 0px rgba(60, 77, 74, 0.08);
          box-shadow: 0px 8px 40px 0px rgba(60, 77, 74, 0.08);
}
@media screen and (min-width: 1400px) {
  .hero__four-frame--team {
    left: 8%;
  }
}
.hero__four-frame--text {
  text-transform: capitalize;
  font-size: 20px;
}
.hero__four-frame--users {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 10;
  left: -12px;
}
.hero__four-frame--users---img1 {
  -webkit-transform: translate(8px, 0);
          transform: translate(8px, 0);
}
.hero__four-frame--users---img3 {
  -webkit-transform: translate(-8px, 0);
          transform: translate(-8px, 0);
  position: relative;
  z-index: -1;
}
.hero__four-frame--num {
  position: relative;
  -webkit-transform: translate(-16px, 0);
          transform: translate(-16px, 0);
  z-index: -2;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
  background: #00715D;
  color: #fff;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.hero__four-slider {
  width: 800px !important;
  margin: 0 0;
}
@media screen and (min-width: 992px) {
  .hero__four-slider {
    padding: 0 0 75px 0;
  }
}
@media screen and (min-width: 1400px) {
  .hero__four-slider {
    margin: 0 0 0 90px;
  }
}
.hero__four-slider--brt {
  border-radius: 100px 0 0 0px;
}
.hero__four-slider--brb {
  border-radius: 0 0 0 100px;
}
.hero__four-slider--img {
  background: #fff;
  padding: 25px 0 0 0;
  overflow: hidden;
  width: 461px !important;
}
.hero__four-slider--img img {
  -webkit-transform: translate(0px, 20px);
          transform: translate(0px, 20px);
  width: 476px;
  height: 532px;
}
.hero__four .swiper-pagination {
  bottom: 5% !important;
}
.hero__four .swiper-pagination-bullet {
  background: #F4FCF7 !important;
  opacity: 1 !important;
  height: 12px;
  width: 12px;
  border: 1px solid transparent !important;
}
.hero__four .swiper-pagination-bullet-active {
  opacity: 1 !important;
  border: 2px solid #00715D !important;
}

.cases {
  overflow-x: hidden;
}
.cases__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .cases__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .cases__grid {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}
.cases__btn {
  margin: 48px 0 0 0;
  border: 1px solid #00715D;
  color: #00715D;
  background: transparent;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  border-radius: 12px;
  padding: 15px 30px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.cases__btn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #00715D;
  color: #fff;
}
.cases__card {
  border-radius: 20px;
  background: #fff;
  overflow: hidden;
  border: 1px solid #F2F4F7;
}
@media screen and (min-width: 400px) {
  .cases__card {
    border: 0 !important;
    -webkit-box-shadow: 0px 8px 12px 0px rgba(77, 77, 77, 0.07);
            box-shadow: 0px 8px 12px 0px rgba(77, 77, 77, 0.07);
  }
}
.cases__card-mb {
  margin: 0 0 0.7rem 0;
}
.cases__card-location {
  font-family: "Inter", sans-serif;
  color: #101828;
  font-size: 12px;
  margin: 0 0 12px 4px;
}
.cases__card-i {
  margin-bottom: 12px;
}
.cases__card-title {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  color: #101828 !important;
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.cases__card-title:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #00715D !important;
}
@media screen and (min-width: 568px) {
  .cases__card-title {
    font-size: 24px;
  }
}
@media screen and (min-width: 768px) {
  .cases__card-title {
    font-size: 18px;
  }
}
@media screen and (min-width: 992px) {
  .cases__card-title {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .cases__card-title {
    font-size: 22px;
  }
}
@media screen and (min-width: 1400px) {
  .cases__card-title {
    font-size: 24px;
  }
}
.cases__card:hover .cases__card-img > img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.cases__card-img {
  position: relative;
  margin: 12px 12px;
  overflow: hidden;
  border-radius: 12px;
}
.cases__card-img img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-radius: 12px;
}
.cases__card-progress {
  height: 5px !important;
  background: #EAECF0 !important;
  margin: 12px 0 0 0;
}
.cases__card-progress--bar {
  background: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688));
  background: linear-gradient(#009688, #009688);
}
.cases__card-tag {
  position: absolute;
  bottom: 16px;
  left: 16px;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  color: #000;
  padding: 10px 14px;
  border-radius: 100px;
}
.cases__card-range {
  border-radius: 10px;
  background: #F2F8F7;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(208, 208, 208, 0.07);
          box-shadow: 0px 8px 20px 0px rgba(208, 208, 208, 0.07);
  padding: 18px 16px;
  margin: 12px 0 0 0;
}
.cases__card-range--bottom {
  display: block;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 16px 0 0 0;
}
@media screen and (min-width: 400px) {
  .cases__card-range--bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.cases__card-range--bottom---m {
  margin: 0 0 12px 0;
}
@media screen and (min-width: 400px) {
  .cases__card-range--bottom---m {
    margin: 0 0;
  }
}
.cases__card-range--dollar {
  background: #DAEBE8;
  color: #00715D;
  padding: 7px 10px;
  border-radius: 50px;
  width: 36px;
  height: 36px;
}
@media screen and (min-width: 768px) {
  .cases__card-range--dollar {
    padding: 6px 9px;
  }
}
@media screen and (min-width: 992px) {
  .cases__card-range--dollar {
    padding: 7px 8px;
  }
}
.cases__card-range--price {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #344054;
  text-transform: capitalize;
}
@media screen and (min-width: 768px) {
  .cases__card-range--price {
    font-size: 12px;
  }
}
@media screen and (min-width: 992px) {
  .cases__card-range--price {
    font-size: 14px;
  }
}
.cases .slick-slider {
  padding: 0 2px;
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}
.cases .slick-list {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}
.cases .slick-dots {
  margin: 30px 0 0 0;
}
@media screen and (min-width: 576px) {
  .cases .slick-slide {
    width: 535px !important;
    margin: 10px 0px 16px 20px;
  }
}
@media screen and (min-width: 768px) {
  .cases .slick-slide {
    width: 350px !important;
    margin: 10px 0px 16px 19px;
  }
}
@media screen and (min-width: 992px) {
  .cases .slick-slide {
    width: 455px !important;
    margin: 10px 0px 16px 32px;
  }
}
@media screen and (min-width: 1200px) {
  .cases .slick-slide {
    width: 357px !important;
    margin: 10px 0px 16px 27px;
  }
}
@media screen and (min-width: 1400px) {
  .cases .slick-slide {
    width: 404px !important;
  }
}
@media screen and (min-width: 1440px) {
  .cases .slick-slide {
    width: 388px !important;
    margin: 10px 0px 16px 42px;
  }
}
.cases__slider3-arrow--left {
  position: absolute;
  top: 36%;
  left: -2.5%;
  content: "";
}
@media screen and (min-width: 992px) {
  .cases__slider3-arrow--left {
    top: 35%;
  }
}
@media screen and (min-width: 1400px) {
  .cases__slider3-arrow--left {
    top: 38%;
  }
}
.cases__slider3-arrow--right {
  position: absolute;
  top: 36%;
  right: -3%;
  content: "";
  -ms-grid-column: 2;
  grid-column: 2/2;
}
@media screen and (min-width: 768px) {
  .cases__slider3-arrow--right {
    right: -9%;
  }
}
@media screen and (min-width: 992px) {
  .cases__slider3-arrow--right {
    top: 35%;
    right: -7%;
  }
}
@media screen and (min-width: 1200px) {
  .cases__slider3-arrow--right {
    right: -4.3%;
  }
}
@media screen and (min-width: 1400px) {
  .cases__slider3-arrow--right {
    top: 38%;
  }
}
.cases__slider3-slider .slick-dots {
  position: relative;
  z-index: 99;
  margin: -12px 0 0 0 !important;
}
.cases__slider3-slider .slick-slide {
  margin: 0px 0px 3rem 0px;
}
@media screen and (min-width: 576px) {
  .cases__slider3-slider .slick-slide {
    width: 513px !important;
    margin: 10px 0px 2rem 20px;
  }
}
@media screen and (min-width: 768px) {
  .cases__slider3-slider .slick-slide {
    width: 330px !important;
    margin: 10px 0px 2rem 26px;
  }
}
@media screen and (min-width: 992px) {
  .cases__slider3-slider .slick-slide {
    width: 441px !important;
    margin: 10px 0px 3rem 32px;
  }
}
@media screen and (min-width: 1200px) {
  .cases__slider3-slider .slick-slide {
    width: 350px !important;
    margin: 10px 0px 16px 27px;
  }
}
@media screen and (min-width: 1400px) {
  .cases__slider3-slider .slick-slide {
    width: 400px !important;
    margin: 10px 0 16px 23px;
  }
}

.cases input[type=range] {
  margin: auto;
  outline: none;
  padding: 0;
  width: 100%;
  height: 6px;
  background-color: #CCC;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#009688), to(#009688));
  background-image: linear-gradient(#009688, #009688);
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;
  cursor: pointer;
  -webkit-appearance: none;
  height: 4px;
}
.cases input[type=range]::-webkit-slider-runnable-track {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}
.cases input[type=range]::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  border: 0;
  background: #009688;
  border-radius: 100%;
  -webkit-box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.cases input[type=range]::-webkit-slider-thumb:active {
  height: 18px;
  width: 18px;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
}
.cases input[type=range]::slider-runnable-track {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  background: transparent;
  -webkit-appearance: none;
}
.cases input[type=range]::slider-thumb {
  width: 15px;
  height: 15px;
  border: 0;
  background: #009688;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 1px 0px rgba(0, 0, 0, 0.1);
  -webkit-appearance: none;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.cases .zero-input[type=range]::-webkit-slider-thumb {
  background: #FFF;
  border: 2px solid #BBB;
}

.about {
  position: relative;
  overflow-x: hidden;
}
.about__layer {
  background: linear-gradient(88deg, #F2F8F7 0%, rgba(242, 248, 247, 0) 100%);
  width: 41%;
  height: 100%;
  position: absolute;
  left: 0;
}
.about__heading {
  margin: 0 0 24px 0;
}
.about__desc {
  font-size: 16px;
  line-height: 28px !important;
}
.about__list {
  margin: 30px 0 0 0;
}
.about__list li:nth-child(2) {
  margin: 14px 0;
}
.about__list li i {
  padding: 8px !important;
  margin: 0 8px 0 0;
}
.about__list li span {
  font-size: 16px !important;
  font-weight: 500 !important;
}
.about__image img {
  border-radius: 20px;
}
.about__image:first-child img {
  -webkit-transform: translate(0, -40px);
          transform: translate(0, -40px);
}
.about__image2 img {
  border-radius: 55px;
  -webkit-transform: rotateY(10deg);
          transform: rotateY(10deg);
}
.about__ele {
  margin: 0 0;
  position: relative;
}
.about__ele::before {
  position: absolute;
  content: "";
  background-image: url(../img/element-3.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 53px;
  height: 180px;
  right: 0;
  bottom: -16%;
}
.about__ele::after {
  position: absolute;
  content: "";
  background-image: url(../img/polygon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 80px;
  right: 25%;
  bottom: -21%;
  -webkit-transform: rotate(8deg);
          transform: rotate(8deg);
}

.service {
  overflow-x: hidden;
}
.service__bg {
  background: -webkit-gradient(linear, left top, left bottom, from(#ECF4F3), to(#FFF));
  background: linear-gradient(180deg, #ECF4F3 0%, #FFF 100%);
}
.service__bg2 {
  background: #E6F1EF;
}
.service__m {
  margin-bottom: 48px !important;
}
.service__p {
  padding: 110px 0 !important;
}
.service__card {
  padding: 28px 40px 52px 40px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  background: #fff;
  -webkit-box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
          box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  border: 2px solid transparent;
}
.service__card-linear {
  background: -webkit-gradient(linear, left top, left bottom, from(#ECF4F3), to(#fff)) !important;
  background: linear-gradient(180deg, #ECF4F3 0%, #fff 100%) !important;
}
.service__card:hover {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  border: 2px solid #fff;
  background: #00715D !important;
}
.service__card:hover .service__card-heading {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #fff !important;
}
.service__card:hover .service__card-desc {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #EAECF0 !important;
}
.service__card:hover .service__card-icon {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  background: rgba(9, 167, 139, 0.7490196078);
}
.service__card:hover .service__card-icon2 {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  background: #fff !important;
}
.service__card:first-child {
  margin: 0 0 50px 0;
}
@media screen and (min-width: 992px) {
  .service__card:first-child {
    margin: 0 0;
  }
}
.service__card-icon {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  background: #00715D;
}
.service__card-icon2 {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
}
.service__card-heading {
  font-size: 25px !important;
  margin: 24px 0 12px 0 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .service__card-heading {
    font-size: 30px !important;
  }
}
.service__card-desc {
  font-size: 14px !important;
  line-height: 22px !important;
}
@media screen and (min-width: 992px) {
  .service__card-desc {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

.service2 {
  overflow-x: hidden;
}
.service2__bg {
  background: -webkit-gradient(linear, left top, left bottom, from(#ECF4F3), to(#FFF));
  background: linear-gradient(180deg, #ECF4F3 0%, #FFF 100%);
}
.service2__m {
  margin-bottom: 48px !important;
}
.service2__card {
  padding: 28px 40px 52px 40px;
  text-align: center;
  border-radius: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
          box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
}
.service2__card:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #00715D;
}
.service2__card:hover .service2__card-boxshow {
  display: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card:hover .service2__card-boxhide {
  display: block;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card:hover .service2__card-handshow {
  display: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card:hover .service2__card-handhide {
  display: block;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card:hover .service2__card-heading {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff;
}
.service2__card:hover .service2__card-desc {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #F2F4F7;
}
.service2__card:first-child {
  margin: 0 0 50px 0;
}
@media screen and (min-width: 992px) {
  .service2__card:first-child {
    margin: 0 0;
  }
}
.service2__card-boxhide {
  display: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card-boxshow {
  display: block;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card-handhide {
  display: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card-handshow {
  display: block;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.service2__card-heading {
  font-size: 25px !important;
  margin: 24px 0 12px 0 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
@media screen and (min-width: 992px) {
  .service2__card-heading {
    font-size: 30px !important;
  }
}
.service2__card-desc {
  font-size: 14px !important;
  line-height: 22px !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
@media screen and (min-width: 992px) {
  .service2__card-desc {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

.org {
  overflow-x: hidden;
}
.org__desc {
  font-size: 16px;
  line-height: 28px !important;
}
.org__li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 0 45px 0;
}
@media screen and (max-width: 567px) {
  .org__li {
    text-align: center;
    display: block !important;
  }
}
.org__li:last-child {
  margin: 0 0;
}
.org__li-name {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: #101828;
}
@media screen and (max-width: 567px) {
  .org__li-name {
    font-size: 25px;
  }
}
.org__li-img {
  background: #00715D;
  border-radius: 100px;
  padding: 15px;
  margin: 0 20px 0 0;
}
@media screen and (max-width: 567px) {
  .org__li-img {
    margin: 0 auto 20px auto;
    width: 70px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.org__li-img--icon {
  width: 36px;
  height: 36px;
}

.num {
  background: #00715D;
  background-image: url(../img/back-drop.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
}
.num__body {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  font-family: "Inter", sans-serif;
  padding: 80px 0;
  text-align: center;
  gap: 50px;
}
@media screen and (min-width: 568px) {
  .num__body {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .num__body {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
  }
}
.num__body-digit {
  font-size: 55px;
  color: #fff;
  font-weight: 700;
}
@media screen and (min-width: 768px) {
  .num__body-digit {
    font-size: 45px;
  }
}
@media screen and (min-width: 992px) {
  .num__body-digit {
    font-size: 55px;
  }
}
@media screen and (min-width: 1200px) {
  .num__body-digit {
    font-size: 72px;
  }
}
.num__body-text {
  color: #CCE3DF;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
@media screen and (min-width: 768px) {
  .num__body-text {
    font-size: 16px;
  }
}
@media screen and (min-width: 992px) {
  .num__body-text {
    font-size: 24px;
  }
}

.testimonial {
  padding: 80px 0;
  background: #E6F1EF;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .testimonial {
    padding: 100px 0;
  }
}
.testimonial__body {
  position: relative;
}
.testimonial__body-left {
  position: absolute;
  top: 43%;
  left: -2%;
}
.testimonial__body-right {
  position: absolute;
  top: 43%;
  right: -2%;
}
.testimonial__body-bg {
  background: #E6F1EF;
  padding: 110px 0;
}
.testimonial__body-items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .testimonial__body-items {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__body-items {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}
.testimonial__body-slider .slick-slide {
  margin: 0 0;
  padding: 0 0 !important;
}
@media screen and (min-width: 768px) {
  .testimonial__body-slider .slick-slide {
    margin: 0 0 0 12px !important;
    padding: 0 0 0 18px !important;
  }
}
.testimonial__arrow-left {
  position: absolute;
  top: 36%;
  left: 0;
  content: "";
}
@media screen and (min-width: 992px) {
  .testimonial__arrow-left {
    top: 32%;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__arrow-left {
    left: -1%;
  }
}
.testimonial__arrow-right {
  position: absolute;
  top: 36%;
  right: -5%;
  content: "";
  -ms-grid-column: 2;
  grid-column: 2/2;
}
@media screen and (min-width: 768px) {
  .testimonial__arrow-right {
    right: -9%;
  }
}
@media screen and (min-width: 992px) {
  .testimonial__arrow-right {
    top: 32%;
    right: -6.5%;
  }
}
@media screen and (min-width: 1200px) {
  .testimonial__arrow-right {
    right: -6%;
  }
}
.testimonial__card {
  padding: 40px;
  background: #fff;
  border-radius: 30px;
  font-family: "Inter", sans-serif;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 2px solid transparent;
}
.testimonial__card:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 2px solid #fff;
  background: #00715D !important;
}
.testimonial__card:hover .testimonial__card-name {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff !important;
}
.testimonial__card:hover .testimonial__card-desc {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #EAECF0 !important;
}
.testimonial__card:hover .testimonial__card-type {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #EAECF0 !important;
}
.testimonial__card-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
}
.testimonial__card-ul li i {
  color: #FDD65B;
}
.testimonial__card-name {
  font-weight: 600;
  font-size: 18px;
  color: #18181B !important;
  text-transform: capitalize;
  text-decoration: none;
}
.testimonial__card-type {
  color: #18181B;
  font-weight: 400;
  font-size: 12px;
  display: block;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.testimonial__card-desc {
  margin: 20px 0;
  font-size: 14px;
  line-height: 24px !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
@media screen and (min-width: 576px) {
  .testimonial__card-desc {
    font-size: 16px;
    line-height: 28px !important;
  }
}
.testimonial .slick-slider {
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: (1fr)[2];
  grid-template-columns: repeat(2, 1fr);
}
.testimonial__slick {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.testimonial .slick-list {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}
.testimonial .slick-dots {
  margin: 30px 0 0 0;
}
.testimonial .slick-slide {
  margin: 0 0;
  padding: 0 16px;
}
@media screen and (min-width: 768px) {
  .testimonial .slick-slide {
    padding: 0 0 0 10px;
    margin: 0 0 0 12px;
  }
}

.team {
  overflow-x: hidden;
}
.team__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .team__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .team__grid {
    -ms-grid-columns: (1fr)[4];
    grid-template-columns: repeat(4, 1fr);
  }
}
.team__img {
  border-radius: 100px;
}
.team__user {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #18181B !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.team__user:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #00715D !important;
}
.team__cate {
  margin: 12px 0 0 0;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  color: #344054;
}
.team__card:hover .team__card-head--social {
  bottom: 10%;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.team__card-head {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  overflow: hidden;
  margin: 0 0 24px 0;
}
.team__card-head img {
  border-radius: 100px;
}
.team__card-head--social {
  position: absolute;
  width: 174px;
  background: #F2F8F7;
  border-radius: 12px;
  bottom: -25%;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.team__card-head ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 18px 20px;
  gap: 20px;
  margin: auto;
}
.team__card-head--link {
  color: #101828 !important;
  font-size: 20px;
}

.blog {
  overflow: hidden;
}
.blog .slick-slide {
  margin: 0 0 0 16px;
}
.blog__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  text-align: center;
  gap: 40px;
}
@media screen and (min-width: 768px) {
  .blog__grid {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .blog__grid {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}
.blog__btn {
  margin: 48px 0 0 0;
  border: 1px solid #00715D;
  color: #00715D;
  background: transparent;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  border-radius: 12px;
  padding: 15px 30px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.blog__btn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #00715D;
  color: #fff;
}
.blog__body-arrow--left {
  position: absolute;
  top: 36%;
  left: -2.5%;
  content: "";
}
@media screen and (min-width: 992px) {
  .blog__body-arrow--left {
    top: 35%;
  }
}
@media screen and (min-width: 1400px) {
  .blog__body-arrow--left {
    top: 38%;
  }
}
.blog__body-arrow--right {
  position: absolute;
  top: 36%;
  right: -3%;
  content: "";
  -ms-grid-column: 2;
  grid-column: 2/2;
}
@media screen and (min-width: 768px) {
  .blog__body-arrow--right {
    right: -5%;
  }
}
@media screen and (min-width: 992px) {
  .blog__body-arrow--right {
    top: 35%;
    right: -3.5%;
  }
}
@media screen and (min-width: 1400px) {
  .blog__body-arrow--right {
    top: 38%;
  }
}
.blog__title {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  color: #101828 !important;
  font-size: 18px;
  font-weight: 600;
  margin: 12px 0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.blog__title:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #00715D !important;
}
@media screen and (min-width: 568px) {
  .blog__title {
    font-size: 24px;
  }
}
@media screen and (min-width: 768px) {
  .blog__title {
    font-size: 18px !important;
  }
}
@media screen and (min-width: 1200px) {
  .blog__title {
    font-size: 22px !important;
  }
}
.blog__desc {
  margin: 12px 0 8px 0;
  line-height: 25px;
}
@media screen and (min-width: 768px) {
  .blog__desc {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .blog__desc {
    font-size: 18px;
    line-height: 33px;
  }
}
.blog__card {
  margin: 20px 0;
  overflow: hidden;
  border: 1px solid #F2F4F7;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 20px;
  background: #fff;
}
.blog__card img {
  border-radius: 20px 20px 0px 0px;
}
.blog__card-line {
  height: 1px;
  background: #b6b6b6;
  width: 28%;
}
@media screen and (min-width: 568px) {
  .blog__card-line {
    width: 31%;
  }
}
.blog__card-span {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #18181B;
  text-transform: capitalize;
}
@media screen and (min-width: 568px) {
  .blog__card-span {
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .blog__card-span {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .blog__card-span {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) {
  .blog__card-span {
    font-size: 16px;
  }
}

.blog__detail-text {
  font-size: 16px !important;
  text-transform: capitalize;
  margin: 0 0 0 10px;
}
@media screen and (min-width: 768px) {
  .blog__detail-text {
    font-size: 18px !important;
  }
}
.blog__detail-m {
  margin: 24px auto 50px auto !important;
}
.blog__detail-box {
  background: #F2F8F7;
  border-radius: 20px;
  border-left: 2px solid #00715D;
  padding: 22px;
  margin: 30px 0 0 0;
}
@media screen and (min-width: 568px) {
  .blog__detail-box {
    padding: 40px;
  }
}
.blog__detail-box--desc {
  margin: 0 0;
  font-family: "Inter", sans-serif;
  color: #344054;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  line-height: 33px;
}
@media screen and (min-width: 400px) {
  .blog__detail-box--desc {
    font-size: 16px;
  }
}
.blog__detail-head {
  margin: 0 0;
}
.blog__detail-head--image {
  border-radius: 16px;
}
@media screen and (min-width: 768px) {
  .blog__detail-head--image {
    border-radius: 40px;
  }
}
.blog__detail-head--heading {
  font-size: 30px;
}
@media screen and (min-width: 992px) {
  .blog__detail-head--heading {
    font-size: 40px;
  }
}
.blog__detail-head--heading---small {
  margin: 24px 0;
  font-size: 25px !important;
}
@media screen and (min-width: 768px) {
  .blog__detail-head--heading---small {
    font-size: 30px !important;
  }
}
.blog__detail-head--desc {
  margin: 0 0;
  font-family: "Inter", sans-serif;
  color: #344054;
  font-size: 15px;
  font-weight: 400;
  line-height: 33px;
}
@media screen and (min-width: 400px) {
  .blog__detail-head--desc {
    font-size: 16px;
  }
}
.blog__detail-head--desc---m {
  margin: 30px 0 12px 0;
}
.blog__detail-head--user {
  margin: 0 16px 0 0;
}
.blog__detail-head--name {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #101828 !important;
  text-transform: capitalize;
  text-decoration: none;
}
.blog__detail-head--tag {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #344054;
  font-size: 16px;
  text-transform: capitalize;
  margin: 6px 0 0 0;
}
.blog__detail-head--list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  margin: 0 0 0 60px;
}
.blog__detail-head--list li {
  position: relative;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #101828;
  font-size: 16px;
  text-transform: capitalize;
}
.blog__detail-head--list li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background: #D0D5DD;
  top: 40%;
  left: -18px;
}
.blog__detail-social {
  right: 0%;
  content: "";
}
@media screen and (min-width: 1200px) {
  .blog__detail-social {
    left: -19%;
    right: auto;
  }
}
.blog__detail-social--text {
  margin: 0 0 20px 0;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #101828;
  font-size: 24px;
  text-transform: capitalize;
}
.blog__detail-social--para {
  margin: 50px 0 14px 0;
  font-family: "Inter", sans-serif;
  color: #4D5563;
  font-size: 16px;
  font-weight: 500;
}
.blog__detail-social li {
  background: #fff;
  -webkit-box-shadow: 0px 8px 34px 0px rgba(77, 77, 77, 0.07);
          box-shadow: 0px 8px 34px 0px rgba(77, 77, 77, 0.07);
  border-radius: 100px;
  width: 48px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto 16px auto;
}
.blog__detail-social a {
  text-decoration: none;
  margin: 0 0;
  padding: 12px 12px;
  border-radius: 100px;
}
.blog__detail-social a img {
  margin: 0 0;
}
.blog__detail-social--fb {
  font-size: 24px;
  color: rgb(0, 178, 255) !important;
}
.blog__detail-social--f {
  font-size: 24px;
  color: #3D6AD6 !important;
}
.blog__detail-social--tw {
  font-size: 24px;
  color: #1DA1F3 !important;
}
.blog__detail-social--in {
  font-size: 24px;
  color: #0073B1 !important;
}
.blog__detail-social--ins {
  font-size: 24px;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.join {
  position: relative;
  z-index: 9;
  margin: 0 0 -11rem 0;
}
@media screen and (min-width: 576px) {
  .join {
    margin: 0 0 -8rem 0;
  }
}
@media screen and (min-width: 768px) {
  .join {
    margin: 0 0 -9rem 0;
  }
}
@media screen and (min-width: 1200px) {
  .join {
    margin: 0 0 -11rem 0;
  }
}
.join__text {
  font-size: 15px !important;
}
@media screen and (min-width: 1200px) {
  .join__text {
    font-size: 20px !important;
  }
}
.join__heading {
  font-size: 30px !important;
  margin: 12px 0 25px 0 !important;
}
@media screen and (min-width: 992px) {
  .join__heading {
    font-size: 42px !important;
    margin: 12px 0 42px 0 !important;
  }
}
.join__card {
  margin: 0 auto;
  border: 0;
  padding: 0;
  border-radius: 40px !important;
  overflow: hidden;
  max-width: 1280px;
}
.join__card-layer {
  margin: 0 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}
.join__card-layer--btn {
  color: #fff !important;
  background: #101828 !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.join__card-layer--btn:hover {
  color: #fff !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #000 !important;
}
.join__card-img {
  height: 400px !important;
}
@media screen and (min-width: 576px) {
  .join__card-img {
    height: 260px !important;
  }
}
@media screen and (min-width: 768px) {
  .join__card-img {
    height: 300px !important;
  }
}
@media screen and (min-width: 992px) {
  .join__card-img {
    height: auto !important;
  }
}

.collection {
  padding: 80px 0;
  background-image: url(../img/footerDrop.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #101828;
}
@media screen and (min-width: 1200px) {
  .collection {
    padding: 110px 0;
  }
}
.collection__text {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 0 0 12px;
}
@media screen and (min-width: 768px) {
  .collection__text {
    font-size: 20px;
  }
}
@media screen and (min-width: 1400px) {
  .collection__text {
    font-size: 24px;
  }
}
.collection__bg {
  border-radius: 100px;
  background: #EAECF0;
}
.collection__list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 20px;
}
@media screen and (min-width: 568px) {
  .collection__list {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1200px) {
  .collection__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.collection__list-items {
  background: #192235;
  padding: 20px 32px;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (min-width: 1200px) {
  .collection__list-items {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.collection__list-icon {
  background: #fff;
  color: #00715D;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.collection__list-icon--img {
  width: 23px;
}

.news__card {
  border-radius: 12px 12px 0 0;
}
.news__card:hover .news__card-img img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.news__card-title {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  color: #101828 !important;
  font-size: 16px;
  font-weight: 600;
  margin: 12px 0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.news__card-title:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #00715D !important;
}
@media screen and (min-width: 1200px) {
  .news__card-title {
    font-size: 24px;
  }
}
.news__card-tag {
  position: absolute;
  bottom: 16px;
  left: 16px;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  color: #000;
  padding: 10px 14px;
  border-radius: 100px;
}
.news__card-span {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #18181B;
  text-transform: capitalize;
}
@media screen and (min-width: 1200px) {
  .news__card-span {
    font-size: 16px;
  }
}
.news__card-img {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
}
.news__card-img--round {
  border-radius: 12px !important;
}
.news__card-img img {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border-radius: 12px;
  -webkit-transform: scale(1);
          transform: scale(1);
}

.donation {
  overflow-x: hidden;
  padding: 80px 0 100px 0;
}
@media screen and (min-width: 1200px) {
  .donation {
    padding: 120px 0 160px 0;
  }
}
.donation__mt {
  margin: 30px 0 0 0;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: (1fr)[1];
  grid-template-columns: repeat(1, 1fr);
}
@media screen and (min-width: 568px) {
  .donation__mt {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
.donation__heading {
  margin: 0 0 24px 0;
}
@media screen and (min-width: 992px) {
  .donation__heading {
    font-size: 35px;
  }
}
@media screen and (min-width: 1200px) {
  .donation__heading {
    font-size: 42px;
  }
}
.donation__element {
  position: absolute;
  left: -15px;
  top: 0%;
}
.donation__element2 {
  position: absolute;
  right: 0%;
  top: -9%;
  z-index: -2;
  -webkit-transform: rotateY(170deg);
          transform: rotateY(170deg);
  width: 100px;
  height: 130px;
}
.donation__element3 {
  position: absolute;
  top: 0%;
  right: 0;
  -webkit-transform: rotateY(190deg);
          transform: rotateY(190deg);
}
@media screen and (min-width: 992px) {
  .donation__element3 {
    width: 16%;
  }
}
@media screen and (min-width: 1200px) {
  .donation__element3 {
    width: auto;
  }
}
.donation__desc {
  font-size: 16px;
  line-height: 28px !important;
}
.donation__btn {
  margin: 30px 0 0 0;
  font-weight: 500;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.donation__video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  margin: 30px 0 0 0;
}
@media screen and (min-width: 568px) {
  .donation__video {
    margin: 0 0;
  }
}
.donation__video-btn {
  position: relative;
  cursor: pointer;
}
.donation__video-player {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation: img_anima 3s ease-in-out infinite;
          animation: img_anima 3s ease-in-out infinite;
}
.donation__video-waves {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.donation__video-waves--div {
  width: 80px;
  height: 80px;
  background: rgba(0, 113, 93, 0.1);
  opacity: 0;
  border-radius: 100%;
  z-index: -1;
  -webkit-animation: waves_anima 3s ease-in-out infinite;
          animation: waves_anima 3s ease-in-out infinite;
}
.donation__video-waves--player {
  cursor: pointer;
  font-size: 40px;
  color: #00715D;
  border-radius: 100px;
  padding: 8px;
}
.donation__video-waves--one {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.donation__video-waves--two {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  position: absolute;
  top: 0;
}
.donation__video-waves--three {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
  position: absolute;
  top: 0;
}
@-webkit-keyframes waves_anima {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
    opacity: 0;
  }
}
@keyframes waves_anima {
  0% {
    -webkit-transform: scale(0.2, 0.2);
            transform: scale(0.2, 0.2);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
            transform: scale(0.9, 0.9);
    opacity: 0;
  }
}
@-webkit-keyframes img_anima {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes img_anima {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.donation__video-text {
  margin: 0 0 !important;
  color: #101828 !important;
  font-size: 16px !important;
}
@media screen and (min-width: 992px) {
  .donation__video-text {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1200px) {
  .donation__video-text {
    font-size: 16px !important;
  }
}
.donation__img {
  position: relative;
}
.donation__img-circle {
  width: 65%;
}
.donation__img-m {
  margin: 4rem 0 -4rem 0;
}
.donation__img-person {
  position: absolute;
  left: -2%;
  top: -30%;
}
.donation__img-shape {
  position: absolute;
  z-index: -1;
  right: -3%;
  content: "";
}

.vbox-content {
  min-height: 0 !important;
  height: 100% !important;
  margin: auto;
}

@media screen and (min-width: 1200px) {
  .venoratio {
    width: 70%;
  }
}

.vbox-close {
  top: 5%;
  right: 3%;
  font-size: 35px;
  color: #fff;
}

.gallery {
  overflow-x: hidden;
}
.gallery__body {
  gap: 20px;
  display: -ms-grid;
  display: grid;
  padding: 0 12px !important;
  margin: 0 !important;
}
@media screen and (min-width: 568px) {
  .gallery__body {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
    padding: 0 0 !important;
  }
}
@media screen and (min-width: 1400px) {
  .gallery__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: inherit;
        flex-wrap: inherit;
    gap: 40px;
  }
}
.gallery__body-layer {
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.gallery__body-layer::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 16px;
  background: rgba(221, 221, 221, 0.2);
  -webkit-transition: -webkit-transform 300ms ease-in;
  transition: -webkit-transform 300ms ease-in;
  transition: transform 300ms ease-in;
  transition: transform 300ms ease-in, -webkit-transform 300ms ease-in;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: right;
          transform-origin: right;
}
@media screen and (min-width: 1400px) {
  .gallery__body-layer::after {
    border-radius: 40px;
  }
}
.gallery__body-layer:hover::after {
  border-radius: 16px;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: left;
          transform-origin: left;
}
@media screen and (min-width: 1400px) {
  .gallery__body-layer:hover::after {
    border-radius: 40px;
  }
}
.gallery__body-two--img {
  position: relative;
  z-index: -9;
  border-radius: 20px !important;
}
.gallery__body img {
  border-radius: 16px;
  height: 100%;
  position: relative;
  z-index: -9;
}
@media screen and (max-width: 567px) {
  .gallery__body img {
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .gallery__body img {
    border-radius: 40px;
    height: auto;
  }
}
.gallery__btn {
  margin: 48px 0 0 0;
  border: 1px solid #00715D;
  color: #00715D;
  background: transparent;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  border-radius: 12px;
  padding: 15px 30px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.gallery__btn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #00715D;
  color: #fff;
}

.mission {
  padding: 80px 0;
  background-image: url(../img/footerDrop.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #101828;
  overflow-x: hidden;
}
@media screen and (min-width: 1200px) {
  .mission {
    padding: 110px;
  }
}
.mission__mb {
  margin-bottom: 100px;
}
.mission__target {
  border: 1px solid #667085;
  border-radius: 100px;
  width: 130px;
  height: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (min-width: 576px) {
  .mission__target {
    width: 156px;
    height: 156px;
  }
}
.mission__target-tr {
  -webkit-transform: translate(-28px, 0);
          transform: translate(-28px, 0);
}
.mission__target-text {
  color: #F2F4F7;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0 0 8px 0;
  padding: 0 25px;
}
.mission__target-num {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  margin: 0 0;
}
.mission__card {
  padding: 28px 40px 32px 40px;
  text-align: start;
  border-radius: 30px;
  background: #fff;
  -webkit-box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
          box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
  border: 2px solid transparent;
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
}
.mission__card:hover {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  border: 2px solid #fff;
  background: #00715D !important;
}
.mission__card:hover .mission__card-heading {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #fff !important;
}
.mission__card:hover .mission__card-desc {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #EAECF0 !important;
}
.mission__card:hover .mission__card-btn {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #fff !important;
}
.mission__card:hover .mission__card-btn--i {
  border: 2px solid #fff;
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  color: #fff !important;
}
.mission__card:hover .mission__card-icon {
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
  background: #fff !important;
}
.mission__card:first-child {
  margin: 0 0 50px 0;
}
@media screen and (min-width: 992px) {
  .mission__card:first-child {
    margin: 0 0;
  }
}
.mission__card-heading {
  font-size: 25px !important;
  margin: 24px 0 12px 0 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .mission__card-heading {
    font-size: 30px !important;
  }
}
.mission__card-desc {
  font-size: 14px !important;
  line-height: 22px !important;
}
@media screen and (min-width: 992px) {
  .mission__card-desc {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}
.mission__card-btn {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #101828;
  text-transform: capitalize;
  text-decoration: none;
  background: transparent;
  border: 0;
  margin: 24px 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.2s linear !important;
  transition: all 0.2s linear !important;
}
.mission__card-btn:hover .mission__card-btn--i {
  -webkit-transform: translate(5px, 0);
          transform: translate(5px, 0);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.mission__card-btn--i {
  margin: 0 0 0 11px;
  font-size: 12px;
  width: 20px;
  height: 20px;
  background: transparent;
  color: #101828;
  border: 2px solid #101828;
  border-radius: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.banner {
  margin: 0 0 80px 0;
  background-image: url(../img/banner-3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .banner {
    margin: 0 0 120px 0;
  }
}
.banner__overlay {
  background-image: url(../img/back-drop-2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 100px 0;
}
@media screen and (min-width: 992px) {
  .banner__overlay {
    padding: 140px 0;
  }
}
.banner__element {
  position: absolute;
  bottom: 0;
  width: 58%;
  right: 0;
}
.banner__polygon {
  position: absolute;
  top: 17%;
  width: 50%;
  right: -2%;
}
.banner__ul {
  margin: 20px 0 0 25px;
}
.banner__ul-list {
  display: inline-block;
  color: #101828;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
  padding: 0 0 0 5px;
}
.banner__ul-link {
  text-decoration: none;
  color: #101828 !important;
  position: relative;
  padding: 0 12px 0 0;
}
.banner__ul-link::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: -1px;
  left: -25px;
  background-image: url(../img/home.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner__ul-link::after {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 1.5%;
  content: "";
  background: #101828;
  -webkit-transform: rotate(19deg);
          transform: rotate(19deg);
}

.support {
  background: #101828;
  padding: 80px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media screen and (min-width: 1200px) {
  .support {
    padding: 110px 0;
  }
}
.support::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background-image: url(../img/line-left.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 450px;
  height: 450px;
  z-index: -1;
}
.support::after {
  position: absolute;
  content: "";
  right: -4%;
  bottom: 0;
  background-image: url(../img/line-right.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 260px;
  height: 310px;
  z-index: -1;
}
.support__heading {
  margin: 0 0 48px 0;
}
@media screen and (min-width: 992px) {
  .support__heading {
    font-size: 35px;
  }
}
@media screen and (min-width: 1200px) {
  .support__heading {
    font-size: 42px;
  }
}
.support__card {
  padding: 28px 40px 52px 40px;
  text-align: center;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
  -webkit-box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
          box-shadow: 0px 12px 43px -12px rgba(34, 85, 58, 0.14);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 2px solid transparent;
}
.support__card:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: 2px solid #fff;
  background: #00715D !important;
}
.support__card:hover .support__card-heading {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff !important;
}
.support__card:hover .support__card-desc {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #EAECF0 !important;
}
.support__card:hover .support__card-icon {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #fff;
}
.support__card:first-child {
  margin: 0 0 50px 0;
}
@media screen and (min-width: 1200px) {
  .support__card:first-child {
    margin: 0 0;
  }
}
.support__card-icon {
  background: rgba(0, 113, 93, 0.1);
}
.support__card-heading {
  font-size: 22px !important;
  margin: 24px 0 12px 0 !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}
.support__card-desc {
  font-size: 14px !important;
  line-height: 22px !important;
}
@media screen and (min-width: 992px) {
  .support__card-desc {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

.education__price {
  margin: 12px 0 50px 0;
}
.education__price-show {
  font-size: 24px !important;
  color: #00715D !important;
}
.education__para {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
}
.education__para-mt {
  margin: 30px 0 0 0;
}
.education__para-mb {
  margin-bottom: 16px;
}
.education__headline {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 30px;
  margin: 30px 0 12px 0;
}
.education__list {
  margin: 0 0 16px 0;
}
.education__list li {
  list-style: inside;
  margin: 0 0 4px 0;
}
.education__txt {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #101828;
  text-transform: capitalize;
}
.education__card {
  border-radius: 30px;
  background: transparent !important;
  overflow: hidden;
  border: 1px solid transparent;
}
.education__card-img {
  position: relative;
  overflow: hidden;
}
.education__card-img img {
  border-radius: 30px;
}
.education__card-tag {
  position: absolute;
  bottom: 20px;
  left: 20px;
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background: #fff;
  color: #000;
  padding: 10px 14px;
  border-radius: 100px;
}
.education__search {
  background: #F2F8F7;
  border-radius: 20px;
  padding: 24px;
}
.education__inp {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #101828 !important;
  background: #fff !important;
  border-radius: 12px;
  padding: 16px 0 16px 12px;
  outline: 0 !important;
  border: 1px solid #EAECF0 !important;
  border: 1px solid transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.education__inp:focus {
  border: 1px solid #18b89c !important;
}
.education__inp-btn {
  position: absolute;
  top: 8%;
  right: 1.3%;
  content: "";
  padding: 11px 24px !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.education__inp-btn:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #10907a !important;
}
.education__inp-btn i {
  color: #fff !important;
}
.education__cases {
  margin: 24px 0 0 0;
  padding: 24px;
  border: 1px solid #EAECF0;
  border-radius: 20px;
}
.education__cases-list {
  margin: 24px 0 0 0;
}
.education__cases-list li {
  border-top: 1px solid #EAECF0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 24px 10px 24px 0;
}
.education__cases-list li:last-child {
  border-bottom: 1px solid transparent;
}
.education__cases-list--m {
  border: 1px solid transparent !important;
  padding: 0 0 !important;
  margin-right: 10px !important;
  margin-bottom: 12px !important;
}
.education__cases-list--m:first-child {
  border-top: 1px solid #EAECF0 !important;
  border-bottom: 1px solid transparent !important;
  padding-top: 24px !important;
}
.education__cases-list--img {
  margin: 0 18px 0 0;
}
.education__cases-list--img img {
  border-radius: 10px;
  width: 80px;
  height: 80px;
}
.education__cases-list--link {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-decoration: none;
  color: #101828 !important;
  font-size: 16px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.education__cases-list--link:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #00715D !important;
}
@media screen and (min-width: 1200px) {
  .education__cases-list--link {
    font-size: 18px;
  }
}
.education__cases-list--tag {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  color: #101828 !important;
  font-size: 18px;
  border: 1px solid #EAECF0;
  padding: 17px 24px;
  border-radius: 100px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.education__cases-list--tag:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: 1px solid #00715D !important;
}
.education__cases-list--date {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-decoration: none;
  color: #344054;
  font-size: 14px;
  margin: 6px 0 0 0;
}

.form {
  background: #F2F8F7;
  border-radius: 30px;
  padding: 24px;
  margin-top: 50px;
  position: relative;
  border-top: 5px solid #00715D;
}
.form__dialog {
  max-width: 846px !important;
}
.form__pd {
  padding: 30px !important;
}
@media screen and (min-width: 768px) {
  .form__pd {
    padding: 25px !important;
  }
}
@media screen and (min-width: 992px) {
  .form__pd {
    padding: 50px !important;
  }
}
.form__mb {
  margin-bottom: 25px;
}
.form__my {
  margin-top: 28px;
  margin-bottom: 28px;
}
.form__payment-py {
  padding: 22px 0;
}
.form__border {
  height: 1px;
  background: #CCE3DF;
  opacity: 1;
  margin: 30px 0 24px 0;
}
.form__icon {
  padding: 9px;
  background: #fff;
  border-radius: 8px;
  height: 44px;
  width: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px solid #EAECF0;
}
.form__dark {
  background: #fff !important;
  border: 1px solid #EAECF0;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.form__dark:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #F2F8F7 !important;
}
.form__price {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #344054;
  margin: 0 0 0 20px;
}
.form__input {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  padding: 10px 0 10px 20px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #EAECF0 !important;
  border-radius: 8px !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.form__input:focus {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: 1px solid #00715D !important;
}
.form__small {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  margin: 0px 0 16px 0;
}
.form__small-bold {
  text-decoration: none;
  font-weight: 600 !important;
  color: #101828 !important;
}
.form__text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #344054;
  margin: 0 0 12px 0;
}
.form__shield {
  padding: 13px 13px;
  background: #EAECF0;
  border-radius: 100px;
  margin: 0 24px 0 0;
}
.form__return {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #101828;
  padding: 12px 20px;
  background: transparent;
  border: 2px solid #D0D5DD !important;
  border-radius: 8px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
@media screen and (min-width: 400px) {
  .form__return {
    font-size: 16px;
  }
}
.form__return i {
  padding: 0 12px 0 0;
  font-size: 13px;
}
@media screen and (min-width: 400px) {
  .form__return i {
    font-size: 20px;
  }
}
.form__img {
  border-radius: 20px;
  margin: 0 24px 0 0;
}
.form__donation li {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 0 12px 0;
}
.form__btn {
  background: #101828 !important;
  padding: 18px 0;
  border-radius: 12px;
  margin: 50px 0 58px 0;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid transparent !important;
}
@media screen and (min-width: 992px) {
  .form__btn {
    width: 400px;
  }
}
.form__drop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #EAECF0 !important;
  border-radius: 8px !important;
  padding: 10px 17px;
}
.form__drop-txt {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-transform: capitalize;
  color: #101828 !important;
}
.form__drop-menu {
  width: 100%;
  border-radius: 8px !important;
  padding: 0;
  overflow: hidden;
  -webkit-transform: translate3d(0, 50px, 0px) !important;
          transform: translate3d(0, 50px, 0px) !important;
}
.form__drop-menu li {
  border-bottom: 1px solid #EAECF0 !important;
  padding: 15px 24px;
}
.form__heading {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #101828;
}
.form__heading-accordion {
  margin: 0 0;
  border-left: 1px solid #CCE3DF !important;
  border-right: 1px solid #CCE3DF !important;
  border-radius: 12px;
  overflow: hidden;
}
.form__heading-accordion--item {
  border: 0;
}
.form__heading-accordion .accordion-item:last-of-type {
  border-bottom: 1px solid #CCE3DF !important;
  border-radius: 12px;
}
.form__heading-accordion .accordion-item:first-of-type {
  border-top: 1px solid #CCE3DF !important;
  border-bottom: 1px solid #CCE3DF !important;
  border-radius: 12px 12px 0 0;
}
.form__heading-accordion--header {
  padding: 0 30px 0 0;
  background: transparent;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.form__heading-accordion--header:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  background: #F2F8F7;
}
.form__heading-accordion .show {
  border-top: 1px solid #CCE3DF;
}
.form__ul {
  display: -ms-grid;
  display: grid;
  gap: 22px;
}
@media screen and (min-width: 500px) {
  .form__ul {
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 768px) {
  .form__ul {
    -ms-grid-columns: (1fr)[3];
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1400px) {
  .form__ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.form__ul li {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  border-radius: 8px;
}
.form__ul-div {
  padding: 12px 22px;
}
.form__ul-inp {
  width: 20px;
  height: 20px;
}
.form__ul-label {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #344054;
  padding: 0 0 0 30px;
  display: block;
  text-transform: capitalize;
  position: relative;
}
@media screen and (min-width: 992px) {
  .form__ul-label {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) {
  .form__ul-label {
    font-size: 16px;
  }
}
.form__ul-label--pointer {
  cursor: pointer;
}
.form__ul-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
input:checked ~ .form__ul-label--checkmark::after {
  display: block;
}
.form__ul-label--checkmark {
  position: absolute;
  top: 30%;
  left: 6%;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #00715D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (min-width: 768px) {
  .form__ul-label--checkmark {
    left: 10%;
  }
}
.form__ul-label--checkmark::after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #00715D;
}
@media screen and (min-width: 1400px) {
  .form__ul-label--checkmark---left {
    left: 17% !important;
  }
}
.form__ul-label--checkmark2 {
  left: 0 !important;
  border-radius: 16% !important;
}
.form__ul-label--checkmark2::after {
  width: 10px !important;
  height: 10px !important;
  border-radius: 20% !important;
}
.form__ul-label--checkmark3 {
  left: 4% !important;
  border-radius: 4px !important;
}
.form__ul-label--checkmark3::after {
  width: 9px !important;
  height: 9px !important;
  border-radius: 2px !important;
}
.form__ul-label--sz {
  font-size: 18px !important;
}
.form__ul-label--lg {
  display: block;
  font-size: 18px !important;
  padding: 0 0 8px 0 !important;
}

.volunteer {
  overflow-x: hidden;
}
.volunteer__requirement {
  border-radius: 20px;
  background: rgba(253, 214, 91, 0.1);
  padding: 30px;
  margin: 30px 0 0 0;
}
.volunteer__text {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-weight: 30px;
  color: #101828;
  margin: 0 0 24px 0;
}
.volunteer__desc {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
}
.volunteer__list {
  margin: 24px 0 50px 0;
}
.volunteer__list li {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #344054;
  margin: 0 0 18px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
@media screen and (min-width: 400px) {
  .volunteer__list li {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.volunteer__list-sub {
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #344054;
}
@media screen and (min-width: 400px) {
  .volunteer__list-sub {
    font-size: 16px;
  }
}
.volunteer__list-content {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: #101828;
}
@media screen and (min-width: 400px) {
  .volunteer__list-content {
    font-size: 20px;
  }
}
.volunteer__list-i {
  background: #FAF2DD;
  font-size: 20px;
  padding: 16px 16px;
  border-radius: 100px;
  color: #101828;
  margin: 0 0 16px 0;
}
@media screen and (min-width: 400px) {
  .volunteer__list-i {
    margin: 0 0 0 0;
    font-size: 28px;
    padding: 20px 20px;
  }
}

.faq {
  margin: 0 0 100px 0;
  background: #F2F8F7;
  overflow-x: hidden;
}
@media screen and (min-width: 1200px) {
  .faq {
    margin: 0 0 140px 0;
  }
}
.faq__body {
  margin: 0 0;
  padding: 80px 0;
}
@media screen and (min-width: 1200px) {
  .faq__body {
    padding: 110px 0;
  }
}
.faq__body-accordion {
  margin: 0 0 0px 0;
  display: -ms-grid;
  display: grid;
  gap: 30px;
}
.faq__body-accordion--desc {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: #344054;
  padding: 0 30px;
}
@media screen and (min-width: 568px) {
  .faq__body-accordion--desc {
    font-size: 16px;
  }
}
.faq__body-accordion .collapse {
  padding: 16px 0 35px 0;
}
.faq__body-accordion--bg {
  outline: 0 !important;
  border: 1px solid transparent !important;
  background: #fff;
  border-radius: 60px !important;
}
.faq__body .accordion-button {
  font-family: "Inter", sans-serif;
  font-weight: 600 !important;
  font-size: 14px !important;
  background: #fff;
  color: #101828 !important;
  padding: 30px 30px;
  border-radius: 60px !important;
  outline: 0 !important;
  border: 1px solid transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
@media screen and (min-width: 568px) {
  .faq__body .accordion-button {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 992px) {
  .faq__body .accordion-button {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1200px) {
  .faq__body .accordion-button {
    font-size: 16px !important;
  }
}
.faq__body .accordion-button::after {
  font-size: 20px;
  color: #101828;
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
  background-image: none !important;
  background: #F2F8F7;
  position: absolute;
  right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 25px 25px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 100px;
}
@media screen and (min-width: 568px) {
  .faq__body .accordion-button::after {
    padding: 30px 30px;
    font-size: 26px;
  }
}
.faq__body .accordion-button:not(.collapsed)::after {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 5px;
  background-image: none !important;
}

.social__body {
  background: #00715D;
  border-radius: 30px;
  padding: 0 20px;
  height: 268px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 9;
}
.social__body--loc {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 225px;
  height: 225px;
  z-index: -1;
  background-image: url(../img/back-drop-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.social__body--con {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 225px;
  height: 225px;
  z-index: -1;
  background-image: url(../img/back-drop-4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.social__body--mail {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 225px;
  height: 225px;
  z-index: -1;
  background-image: url(../img/back-drop-3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.social__text {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  text-transform: capitalize;
  margin: 24px 0 12px 0;
}
.social__number {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #F2F8F7;
}
.social__i {
  margin: auto;
  background: #E6F1EF;
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
}

.contact__form {
  margin: 0 0;
  background: #F2F8F7;
  border-radius: 40px;
  padding: 70px 20px;
}
@media screen and (min-width: 768px) {
  .contact__form {
    padding: 70px 50px;
  }
}
@media screen and (min-width: 992px) {
  .contact__form {
    padding: 70px 100px;
  }
}
@media screen and (min-width: 1400px) {
  .contact__form {
    padding: 110px 178px;
  }
}
.contact__form-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 30px 40px;
}
@media screen and (min-width: 568px) {
  .contact__form-body {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
    grid-template-columns: repeat(2, 1fr);
  }
}
.contact__form-body--comnt {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  border: 1px solid transparent !important;
}
.contact__form-body--comnt:focus {
  border: 1px solid #18b89c !important;
}
.contact__form-inp {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  padding: 10px 0 10px 20px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #EAECF0 !important;
  border-radius: 8px !important;
  border: 1px solid transparent !important;
}
.contact__form-inp:focus {
  border: 1px solid #18b89c !important;
}
.contact__map {
  position: relative;
  margin: 0 0 -14.5rem 0;
}

.error {
  margin: 0 0 140px 0;
}
.error__m {
  margin: 48px 0 24px 0;
}
.error img {
  width: 850px;
  margin: auto;
}

.sign {
  margin: 0 0;
}
.sign__layer {
  background: #E6F1EF;
}
.sign__slider-item {
  height: 100vh;
}
.sign__body {
  height: 100vh;
}
@media screen and (min-width: 1600px) {
  .sign__body {
    overflow: hidden;
  }
}
.sign__card {
  margin: 0 0;
}
.sign__card-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: linear-gradient(1deg, rgba(0, 113, 93, 0) 0%, #037762 100%);
}
.sign__card-title {
  font-weight: 500 !important;
  margin: 50px 0 0 0;
}
@media screen and (min-width: 768px) {
  .sign__card-title {
    margin: 110px 0 0 0;
  }
}
.sign__card-img {
  -o-object-fit: cover;
     object-fit: cover;
}
.sign__card-element {
  position: absolute;
  bottom: 8%;
  right: -50%;
  z-index: 9;
  width: 50%;
}
.sign__card-p {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #EAECF0;
  margin: 24px 0 0 0;
}
.sign__card-icons {
  position: relative;
}
.sign__card-icons i {
  cursor: pointer;
  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: transparent;
  color: #fff !important;
  width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.sign__card-icons i:hover {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: rgba(230, 241, 239, 0.5);
}
@media screen and (min-width: 400px) {
  .sign__card-icons i {
    width: 50px;
    height: 50px;
  }
}
.sign__card-icons--left {
  position: absolute;
  bottom: 8%;
  z-index: 9;
  right: 24%;
}
@media screen and (min-width: 568px) {
  .sign__card-icons--left {
    right: 17%;
  }
}
@media screen and (min-width: 1400px) {
  .sign__card-icons--left {
    bottom: 10%;
  }
}
.sign__card-icons--right {
  position: absolute;
  bottom: 8%;
  z-index: 9;
  right: 6%;
}
@media screen and (min-width: 1400px) {
  .sign__card-icons--right {
    bottom: 10%;
  }
}
.sign__small {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #344054;
}
@media screen and (min-width: 992px) {
  .sign__small {
    font-size: 14px;
  }
}
@media screen and (min-width: 1366px) {
  .sign__small {
    font-size: 16px;
  }
}
.sign__text {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #475467;
  margin: 0 0 -10px 0;
}
.sign__text-show {
  display: block !important;
}
.sign__position {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
}
@media screen and (min-width: 992px) {
  .sign__position {
    position: relative;
    top: 0;
  }
}
.sign__form {
  margin: 0 0;
  padding: 20px 12px;
  background: #fff;
}
@media screen and (min-width: 568px) {
  .sign__form {
    padding: 0 50px 0 50px;
  }
}
@media screen and (min-width: 992px) {
  .sign__form {
    padding: 40px;
    border-radius: 20px;
  }
}
@media screen and (min-width: 1366px) {
  .sign__form {
    margin: 0 0;
    padding: 30px;
  }
}
@media screen and (min-width: 1600px) {
  .sign__form {
    padding: 50px;
  }
}
.sign__form-mb {
  margin-bottom: 18px;
}
@media screen and (min-width: 992px) {
  .sign__form-mb {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 1200px) {
  .sign__form-mb {
    margin-bottom: 18px;
  }
}
.sign__form-heading {
  margin: 0px 0 30px 0;
  font-size: 36px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  color: #101828;
  text-transform: capitalize;
}
@media screen and (min-width: 992px) {
  .sign__form-heading {
    margin: 18px 0;
    font-size: 30px;
  }
}
@media screen and (min-width: 1366px) {
  .sign__form-heading {
    margin: 50px 0 30px 0;
    font-size: 36px;
  }
}
.sign__form-label {
  text-transform: capitalize;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #101828;
  margin: 0 0 6px 0;
}
.sign__form-rem {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 13px;
  color: #101828;
  padding: 0 0 0 24px;
  display: block;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
}
@media screen and (min-width: 400px) {
  .sign__form-rem {
    font-size: 14px;
  }
}
.sign__form-rem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
input:checked ~ .sign__form-rem--checkmark::after {
  display: block;
}
.sign__form-rem--checkmark {
  position: absolute;
  top: 4%;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #00715D;
}
.sign__form-rem--checkmark::after {
  content: "";
  position: absolute;
  display: none;
  top: 3px !important;
  left: 3px !important;
  width: 7px !important;
  height: 7px !important;
  border-radius: 2px !important;
  background: #00715D;
}
.sign__form-input {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #344054;
  padding: 10px 0 10px 20px;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  outline: 0 !important;
  border: 1px solid #EAECF0 !important;
  border-radius: 8px !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.sign__form-input:focus {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  border: 1px solid #00715D !important;
}
.sign__form-link {
  color: #00715D !important;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
}
@media screen and (min-width: 400px) {
  .sign__form-link {
    font-size: 14px;
  }
}
.sign__btn {
  margin: 0 0;
  outline: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  padding: 14px 18px;
}
@media screen and (min-width: 576px) {
  .sign__btn {
    padding: 19px 18px;
    font-size: 16px !important;
  }
}
@media screen and (min-width: 992px) {
  .sign__btn {
    font-size: 14px !important;
  }
}
@media screen and (min-width: 1366px) {
  .sign__btn {
    font-size: 16px !important;
  }
}
.sign__btn-up {
  margin: 16px 0 30px 0;
  color: #00715D !important;
  border-radius: 8px;
  text-transform: capitalize;
  border: 1px solid #00715D !important;
  background: #fff !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.sign__btn-up:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #fff !important;
  background: #00715D !important;
}
@media screen and (min-width: 992px) {
  .sign__btn-up {
    margin: 16px 0;
  }
}
@media screen and (min-width: 1200px) {
  .sign__btn-up {
    margin: 16px 0 30px 0;
  }
}
.sign__btn-in {
  color: #fff !important;
  background: #00715D !important;
  border-radius: 8px;
  text-transform: capitalize;
  border: 1px solid #00715D !important;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}
.sign__btn-in:hover {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  color: #00715D !important;
  background: #fff !important;
}
@media screen and (min-width: 992px) {
  .sign__btn-in {
    margin: 0 20px 0 0 !important;
  }
}

.footer {
  position: relative;
  z-index: 8;
  background-image: url(../img/footerDrop.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #101828;
  padding: 16rem 0 48px 0;
}
@media screen and (min-width: 1200px) {
  .footer {
    padding: 20rem 0 48px 0;
  }
}
.footer__desc {
  color: #EAECF0;
  margin: 12px 0 20px 0;
}
@media screen and (min-width: 1200px) {
  .footer__desc {
    font-size: 16px;
  }
}
.footer__social {
  margin: 0 0;
}
.footer__social-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
.footer__social-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-decoration: none;
  color: #fff !important;
  background: #131D30;
  border: 1px solid #202F4E;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  border-radius: 100px;
  font-size: 20px;
  width: 48px;
  height: 48px;
}
.footer__social-link:hover {
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  background: #18b89c;
}
.footer__sub {
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  margin: 0 0 20px 0;
}
.footer__inp {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #fff !important;
  background: #1D2939 !important;
  border-radius: 12px;
  padding: 16px 0 16px 12px;
  outline: 0 !important;
  border: 1px solid transparent !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.footer__inp:focus {
  border: 1px solid #18b89c !important;
}
.footer__inp-btn {
  color: #fff !important;
  position: absolute;
  top: 8%;
  right: 1.3%;
  content: "";
  padding: 11px 24px !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.footer__inp-btn:hover {
  color: #fff !important;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  background: #10907a !important;
}
.footer__list {
  margin: 0 0;
  padding: 0 0;
}
.footer__listli:last-child {
  margin: 0 0;
}
.footer__list li {
  margin: 0 0 16px 0;
}
.footer__list-item {
  text-decoration: none;
  color: #fff !important;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  position: relative;
}
.footer__list-item:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #18b89c !important;
}
.footer__border {
  background: #344054;
  margin: 48px 0;
  height: 2px !important;
}
.footer__bottom {
  color: #EAECF0;
}